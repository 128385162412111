import React from 'react';
import { DataCallLogResponse } from '../../types/DataCallLogResponse';

import './AdmitReviewStats.css';

interface AdmitReviewStatsProps {
  callLog: DataCallLogResponse;
}

export const AdmitReviewStats: React.FC<AdmitReviewStatsProps> = ({ callLog }) => {

  const getColorAndPercentage = (importance:number) => {
    let colorCode;
    if (importance <= 33) {
      colorCode = '#DA3F3F';
    } else if (importance <= 66) {
      colorCode = '#DACA3F';
    } else {
      colorCode = '#2C931B';
    }
    const roundedPercentage = Math.round(importance);
    return { colorCode, percentage: `${roundedPercentage}%` };
  };

  const { colorCode, percentage } = getColorAndPercentage(callLog.importance);

  const circleStyle = {
    '--i': percentage,
    '--clr': colorCode
  } as React.CSSProperties;

  const importanceReason = callLog.importance_reason || "No reason generated yet!";

  return (
    <div className="last-call-range">
      <div className="last-call-priority card-wrapper">
        <h3>Admit Probability</h3>
        <div className="circle" style={circleStyle} >
          <h4>{percentage}</h4>
        </div>
        <p className="last-call-tooltip">{importanceReason}</p>
      </div>
      {/* <div className="last-call-review card-wrapper">
        <h3>Review Required</h3>
        <img src={callLog.review_required ? greenFlag : redFlag} alt={callLog.review_required ? 'Green flag' : 'Red flag'} />
      </div> */}
    </div>
  );
};
