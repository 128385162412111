import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabs from 'rc-tabs';
import 'rc-tabs/assets/index.css';
import { useAgentContext } from '../../contexts/AgentContext';
import { useAgents } from '../../hooks/useAgents';
import { AgentItem } from '../../components/AgentItem';
import { Loader } from '../../components/Loader';
import { useFiltersContext } from '../../contexts/FilterContext';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { AgentPerformanceTable } from '../../components/AgentPerformanceTable';
import { AgentCallsTable } from '../../components/AgentCallsTable';
import { AgentMessages } from '../../components/AgentMessages/AgentMessages';

const AgentDetailPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { id: agentId } = useParams<{ id: string }>();
  const { setSelectedAgent, selectedAgent } = useAgentContext();
  const { filters } = useFiltersContext();

  const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  const searchParams = useMemo(() => ({
    agent_id: agentId,
    content: 'full',
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
    cities: filters.cities,
    states: filters.states,
    postal_codes: filters.postalCodes,
    campaigns: filters.campaign,
    sources: filters.source,
    keywords: filters.keyword,
    page: 1,
  }), [agentId, filters.dateRange.startDate, filters.dateRange.endDate]);

  const { agents: agentDetails, loading: loadingDetails } = useAgents({ searchParams });

  useEffect(() => {
    if (agentDetails && agentDetails.agents.length > 0) {
      const fetchedAgent = agentDetails.agents[0];
      setSelectedAgent(fetchedAgent);
    }
  }, [agentDetails, setSelectedAgent]);

  if (loadingDetails) return <div className="container"><Loader /></div>;

  if (!agentDetails || agentDetails.agents.length === 0) {
    return <div className="container">No agent data available.</div>;
  }

  const items = [
    {
      key: '1',
      label: 'Details',
      children: selectedAgent ? (
        <div className='tab-content'>
          <AgentPerformanceTable />
        </div>
      ) : (
        <Loader />
      ),
    },
    {
      key: '2',
      label: 'Calls',
      children: selectedAgent ? (
        <div className='tab-content'>
         <AgentCallsTable agentId={agentId!} />
        </div>
      ) : (
        <Loader />
      ),
    },
    {
      key: '3',
      label: 'Messages',
      children:  <div className='tab-content'>
        <AgentMessages agentId={agentId!} />
        </div>,
    },
  ];

  return (
    <div className="container">
      <section className="agent-summary">
        {selectedAgent ? <AgentItem agent={selectedAgent} /> : <div>Loading agent details...</div>}
      </section>
      <Tabs
        items={items}
        defaultActiveKey="1"
        onChange={(key) => setActiveTab(key)}
      />
    </div>
  );
};

export default AgentDetailPage;
