import React, { useState, useEffect, useRef } from 'react';
import './EditCustomerPopup.css';
import closeIcon from '../../assets/images/svg/close-dark.svg';
// import { SingleSelectDropdown } from '../SingleSelectDropdown';
// import { useCitiesData } from '../../hooks/useCitiesData';
// import { useStatesData } from '../../hooks/useStatesData';
import { useUpdateCustomer } from '../../hooks/useUpdateCustomer';
import { useCustomerContext } from '../../contexts/CustomerContext';

interface Props {
  customer: {
    uuid: string;
    customer_name: string;
    customer_number: string;
    call_count: number;
    last_called_at: Date;
    first_called_at: Date;
    city: string;
    state: string;
    postal_code: string;
    latitude: string;
    longitude: string;
    gender: 'male' | 'female' | 'unknown';
    linked_customer_numbers: { [key: string]: string };
  };
  onClose: () => void;
}

export const EditCustomerPopup: React.FC<Props> = ({ customer, onClose }) => {
  // const { cities } = useCitiesData();
  // const { states } = useStatesData();
  const { updateCustomer, loading, error } = useUpdateCustomer();
  const { updateCustomer: updateCustomerContext } = useCustomerContext();
  const [name, setName] = useState(customer.customer_name);
  const [gender, setGender] = useState<'male' | 'female' | ''>(customer.gender === 'unknown' ? '' : customer.gender);
  const [formError, setFormError] = useState<string | null>(null); // State for form error
  const popupRef = useRef<HTMLDivElement>(null);

  const handleSave = async () => {
    const nameWithoutSpaces = name.replace(/[^a-zA-Z]/g, '');
    if (!nameWithoutSpaces || nameWithoutSpaces.length < 4) {
      setFormError('Name must be at least 4 letters.');
      return;
    }

    try {
      const updatedCustomer = {
        ...customer,
        customer_name: name,
        gender: gender as 'male' | 'female',
        // city,
        // state,
      };
      await updateCustomer({ customerId: customer.uuid, customerData: updatedCustomer });
      updateCustomerContext(updatedCustomer);
      onClose();
    } catch (err) {
      console.error('Failed to update customer:', err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="popup-overlay">
      <div className="popup-overlay-content card-wrapper" ref={popupRef}>
        <img src={closeIcon} alt="Close" className="close-icon" onClick={onClose} />
        <h3>Edit Customer</h3>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (formError) setFormError(null); // Clear form error when user types
              }}
            />
            {formError && <p className="form-error">{formError}</p>}
          </div>
          <div className="form-group">
            <label>Gender:</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  value="female"
                  checked={gender === 'female'}
                  onChange={(e) => setGender(e.target.value as 'female')}
                />
                Female
              </label>
              <label>
                <input
                  type="radio"
                  value="male"
                  checked={gender === 'male'}
                  onChange={(e) => setGender(e.target.value as 'male')}
                />
                Male
              </label>
            </div>
          </div>
          {/* <div className="form-group">
            <label>City:</label>
            <SingleSelectDropdown values={cities} selectedValue={city} onChange={setCity} name="City" />
          </div>
          <div className="form-group">
            <label>State:</label>
            <SingleSelectDropdown values={states} selectedValue={state} onChange={setState} name="State" />
          </div> */}
          <div className="form-actions">
            <button className='cta cta-secondary' type="button" onClick={onClose}>Cancel</button>
            <button className='cta cta-main' type="button" onClick={handleSave} disabled={loading || gender === ''}>
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default EditCustomerPopup;
