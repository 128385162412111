import React, { useState, useEffect } from 'react';
import { AdminTable } from '../AdminTable';
import { AdminRole } from '../../types/AdminRole';
import { formatDateTime } from '../../utils/formatDateTime';
import { EditRolePopup } from '../EditRolePopup/EditRolePopup';
import { AddRolePopup } from '../AddRolePopup/AddRolePopup';
import { useAdminRoles } from '../../hooks/useAdminRoles';
import { ReactComponent as EditIcon } from '../../assets/images/svg/edit-user1.svg';
import { Loader } from '../Loader';

export const RolesTable: React.FC = () => {
  const { roles, addRole, editRole, loading, error } = useAdminRoles(); // Fetch roles and methods
  const [editingRole, setEditingRole] = useState<AdminRole | null>(null);
  const [isAddRolePopupOpen, setIsAddRolePopupOpen] = useState(false);
  const [roleList, setRoleList] = useState<AdminRole[]>([]);

  useEffect(() => {
    if (roles) {
      setRoleList(roles);
    }
  }, [roles]);

  const handleSaveNewRole = async (newRole: { role_name: string; is_super_admin: boolean; created_by: string }) => {
    try {
      await addRole(newRole); // Call addRole from the hook
      setIsAddRolePopupOpen(false); // Close popup after adding
    } catch (error) {
      console.error('Failed to add role:', error);
    }
  };

  const handleEditRole = async (updatedRole: { role_name: string; is_super_admin: boolean; updated_by: string }) => {
    if (!editingRole) return;
    try {
      await editRole(editingRole.id, updatedRole); // Call editRole from the hook
      setEditingRole(null);
    } catch (error) {
      console.error('Failed to edit role:', error);
    }
  };

  const columns = ['Role Name', 'Is Super Admin', 'Created At', 'Updated At', 'Actions'];

  if (loading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <AdminTable<AdminRole>
        columns={columns}
        data={roleList}
        renderRow={(role) => (
          <>
            <td>{role.role_name}</td>
            <td>{role.is_super_admin ? 'Yes' : 'No'}</td>
            <td>{formatDateTime(role.created_at)}</td>
            <td>{formatDateTime(role.updated_at)}</td>
            <td>
              <div className="admin-table-icons">
                <button onClick={() => setEditingRole(role)}>
                  <EditIcon className="admin-table-icon" />
                </button>
              </div>
            </td>
          </>
        )}
        entityName="Roles"
        onAdd={() => setIsAddRolePopupOpen(true)}
        showSearchBar={true}
      />

      {editingRole && (
        <EditRolePopup
          role={editingRole}
          onClose={() => setEditingRole(null)}
          onSave={handleEditRole}
        />
      )}

      {isAddRolePopupOpen && (
        <AddRolePopup
          onClose={() => setIsAddRolePopupOpen(false)}
          onSave={handleSaveNewRole}
        />
      )}
    </>
  );
};

export default RolesTable;
