interface FAQ {
  question: string;
  answer: string;
}

export const faqData: FAQ[] = [
  {
    question: "What is Hot Analytics?",
    answer: "Hot Analytics is an internal tool developed and managed by Haven Health Management to provide comprehensive data visualization, transcription services, anomaly detection, and insights powered by proprietary and external data sources."
  },
  {
    question: "Who developed Hot Analytics?",
    answer: "Hot Analytics is developed and maintained by Haven Health Management."
  },
  {
    question: "Who can access this platform?",
    answer: "Only authorized users from Haven Health Management and relevant departments can access this proprietary platform."
  },
  {
    question: "How do I log into Hot Analytics?",
    answer: "You can log into Hot Analytics using your company-provided credentials through the secure authentication portal."
  },
  {
    question: "What is the role of SIFRA?",
    answer: "SIFRA is the Info-bot designed to guide users through the platform, provide information about features, and assist with queries. If SIFRA is unable to assist, it will direct you to contact pshah@havenhealthmgmt.org."
  },
  {
    question: "Is there any disclaimer I should be aware of?",
    answer: "Yes, this is an internal proprietary tool. Information shared or transmitted through this platform should not be copied, shared, or transmitted outside of authorized channels."
  },
  {
    question: "What is the purpose of Customer View?",
    answer: "The Customer View feature allows users to view detailed information from a customer's perspective, including their interactions, call history, and relevant data insights."
  },
  {
    question: "How do I access the Customer View?",
    answer: "You can access the Customer View by navigating to the 'Customer' tab in the application dashboard."
  },
  {
    question: "Can I search for specific customer data?",
    answer: "Yes, the search functionality allows you to filter and view specific customer data based on set parameters."
  },
  {
    question: "What does the Agent View feature provide?",
    answer: "The Agent View offers a detailed look into the activities, call interactions, and performance metrics of agents within the system."
  },
  {
    question: "How is the Agent View different from Customer View?",
    answer: "While Customer View focuses on customer data, the Agent View is tailored to showcase the performance, call records, and metrics of individual agents."
  },
  {
    question: "What is the Dashboard feature used for?",
    answer: "The Dashboard provides visual insights and data analytics, offering an overview of system performance, customer interactions, and key metrics through graphs and charts."
  },
  {
    question: "Can I customize the Dashboard view?",
    answer: "Yes, depending on your role, you may have permissions to customize the data widgets and charts displayed on your dashboard."
  },
  {
    question: "How do I add new data widgets to my Dashboard?",
    answer: "Navigate to the settings panel on your dashboard and select 'Add Widgets' to include new data visualizations."
  },
  {
    question: "What is the RBAC Admin Panel?",
    answer: "The RBAC Admin Panel allows administrators to control user permissions and assign roles to ensure only authorized personnel have access to specific features and data."
  },
  {
    question: "How can I add a new user role?",
    answer: "In the RBAC Admin Panel, select the 'Add Role' button, fill out the role details, and assign the necessary permissions."
  },
  {
    question: "How can I edit existing user roles?",
    answer: "Go to the 'User Roles' section in the RBAC Admin Panel, choose the role you wish to edit, and modify the permissions accordingly."
  },
  {
    question: "What is Ask-AI in Hot Analytics?",
    answer: "Ask-AI is an advanced AI feature powered by proprietary data to assist users in generating insights, answering complex queries, and providing recommendations based on system data."
  },
  {
    question: "How do I interact with Ask-AI?",
    answer: "You can interact with Ask-AI by typing your query in the designated AI chat box within the Hot Analytics platform."
  },
  {
    question: "Can Ask-AI provide summaries or insights based on uploaded documents?",
    answer: "Yes, Ask-AI can process and analyze uploaded documents, providing detailed summaries or insights as required."
  },
  {
    question: "What is the Onboarding feature?",
    answer: "The Onboarding feature is designed to help new users familiarize themselves with the platform, offering guided tours and documentation to ease the learning curve."
  },
  {
    question: "How do I start the Onboarding process?",
    answer: "On your first login, you will be automatically guided through the Onboarding process, or you can access it anytime by navigating to the 'Help' section."
  },
  {
    question: "What is the Info-bot?",
    answer: "Info-bot is an integrated chatbot that serves as a go-to guide for any application usage questions or issues users may encounter."
  },
  {
    question: "Can Info-bot help with troubleshooting?",
    answer: "Yes, Info-bot can guide users through common troubleshooting steps. If the issue persists, you will be directed to contact support."
  },
  {
    question: "How do I ask a question to Info-bot?",
    answer: "Simply type your query into the chatbot interface located on the bottom-right corner of the platform."
  },
  {
    question: "What is the Notifications feature for?",
    answer: "Notifications keep users informed of new feature releases, updates, and user-set events on opted channels."
  },
  {
    question: "How can I opt in for notifications?",
    answer: "You can opt in for notifications by going to your user profile settings and selecting the channels you wish to receive updates on (e.g., email, SMS, Slack)."
  },
  {
    question: "What is the On-Demand Transcription and Summary Service?",
    answer: "This service supports transcription and summarization of audio and video files in real-time, with support for both ad hoc files and CTM data."
  },
  {
    question: "What file types are supported?",
    answer: "The service supports various audio and video file types such as MP3, WAV, MP4, and AVI."
  },
  {
    question: "How do I upload a file for transcription?",
    answer: "Navigate to the 'Transcription Service' section, upload your audio or video file, and initiate the transcription process."
  },
  {
    question: "How do I update my profile information?",
    answer: "You can update your profile information by navigating to the 'User Settings' section in the top-right corner of the application."
  },
  {
    question: "How can I reset my password?",
    answer: "You can reset your password by clicking on 'Forgot Password' on the login page and following the instructions sent to your registered email."
  },
  {
    question: "What is the maximum file size I can upload for transcription?",
    answer: "The maximum file size for transcription uploads is 2GB for audio and video files."
  },
  {
    question: "Which file formats are supported for transcription?",
    answer: "Hot Analytics supports MP3, WAV, MP4, AVI, and several other audio and video formats for transcription."
  },
  {
    question: "How can I view my transcription history?",
    answer: "You can view your transcription history by navigating to the 'Transcription Service' section and selecting 'History' from the menu."
  },
  {
    question: "What should I do if a transcription fails?",
    answer: "If a transcription fails, you will see an error message in the transcription history. You can retry the upload or contact support for assistance."
  },
  {
    question: "Can I download my transcriptions?",
    answer: "Yes, you can download completed transcriptions in text format from the transcription history section."
  },
  {
    question: "What are the different user roles in Hot Analytics?",
    answer: "Hot Analytics has several roles such as 'user', 'admin', and 'super-admin' with varying permissions."
  },
  {
    question: "How can I request access to a specific feature?",
    answer: "You can request access to features by contacting your admin or by submitting a support request through the 'Support' section."
  },
  {
    question: "How do I report an issue?",
    answer: "To report an issue, navigate to the 'Support' section, fill out the issue form, and submit it. You can also attach screenshots if needed."
  },
  {
    question: "Can I export my data from Hot Analytics?",
    answer: "Yes, you can export data in JSON or CSV format by using the 'Export' button available in the dashboard and customer view."
  },
  {
    question: "What is the purpose of the Ask-AI feature?",
    answer: "Ask-AI helps users answer queries, generate insights, and provide recommendations based on the proprietary data available in Hot Analytics."
  },
  {
    question: "How can I enable notifications for new updates?",
    answer: "You can enable notifications in your user profile settings under the 'Notifications' tab, where you can choose your preferred channels."
  },
  {
    question: "What kind of notifications does Hot Analytics send?",
    answer: "Hot Analytics sends notifications for new feature releases, important updates, and user-set event triggers."
  },
  {
    question: "How can I view the analytics dashboard?",
    answer: "You can access the analytics dashboard by selecting the 'Dashboard' option from the main menu, where you can view various data visualizations."
  },
  {
    question: "How do I filter data on the dashboard?",
    answer: "You can filter data by applying various filter options available at the top of the dashboard, such as date range, agent name, or customer details."
  },
  {
    question: "How secure is the data in Hot Analytics?",
    answer: "Hot Analytics follows strict data security protocols, including encryption, role-based access control, and activity logging, to ensure data security."
  },
  {
    question: "How can I change my notification preferences?",
    answer: "You can change your notification preferences in your user profile settings, under the 'Notifications' tab."
  },
  {
    question: "What are the key features of the Info-bot?",
    answer: "The Info-bot provides users with answers to common queries, guides on platform usage, and troubleshooting steps. It also escalates issues to support if needed."
  },
  {
    question: "How do I perform a search for specific customer records?",
    answer: "You can search for specific customer records by using the search bar in the 'Customer View' section, applying filters such as name or call history."
  },
  {
    question: "What is the RBAC feature?",
    answer: "RBAC (Role-Based Access Control) allows administrators to manage user roles and permissions, ensuring only authorized users can access specific features."
  },
  {
    question: "How can I view the activity log?",
    answer: "You can view the activity log by accessing the 'Admin Panel' and selecting 'Activity Logs', which records all system activities for auditing purposes."
  },
  {
    question: "Can I customize the data shown on my dashboard?",
    answer: "Yes, you can customize the data shown on your dashboard by adding or removing widgets and configuring their settings to match your preferences."
  },
  {
    question: "What are the different data sources integrated into Hot Analytics?",
    answer: "Hot Analytics integrates with several data sources, including HA, CTM, SF, and vector data from Ads, Analytics, Asana, and Slack."
  },
  {
    question: "What is the Anomaly and Fact Finder service?",
    answer: "The Anomaly and Fact Finder service notifies users of any identified patterns, facts, or anomalies in the data, sending alerts via Slack or a dashboard board."
  },
  {
    question: "How do I enable two-factor authentication?",
    answer: "You can enable two-factor authentication (2FA) in your user settings under the 'Security' tab."
  },
  {
    question: "What do I do if I can't access a feature?",
    answer: "If you can't access a feature, verify your role permissions or contact your admin to request the necessary access rights."
  },
  {
    question: "How do I delete a customer record?",
    answer: "You can delete a customer record by navigating to the 'Customer View', selecting the customer, and clicking the 'Delete' option, if you have the necessary permissions."
  },
  {
    question: "Can I see agent performance metrics?",
    answer: "Yes, agent performance metrics can be viewed in the 'Agent View', where you can analyze call times, response rates, and other key metrics."
  }
];