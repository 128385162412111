import { client } from "../utils/fetchData";
import { UploadImageResponse } from "../types/UploadImageResponse";

export const uploadImage = async (token: string, imageBase64: string): Promise<UploadImageResponse> => {
  const base64Prefix = 'data:image/jpeg;base64,';
  if (!imageBase64.startsWith(base64Prefix)) {
    throw new Error('Invalid image base64 string or format.');
  }

  const base64Data = imageBase64.substring(base64Prefix.length);

  let binaryString;
  try {
    binaryString = atob(base64Data);
  } catch (error) {
    console.error('Error decoding base64 string:', error);
    throw new Error('Failed to decode base64 string.');
  }

  const byteNumbers = new Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteNumbers[i] = binaryString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });

  const formData = new FormData();
  formData.append('file', blob, 'image.jpeg');

  return client.post<UploadImageResponse>('upload-image', token, formData);
};
