import React from 'react';
import './CategoryItem.css';

interface CategoryItemProps {
  category: string;
  topics: string[];
  iconSrc: string;
  altText: string;
}

export const CategoryItem: React.FC<CategoryItemProps> = ({ category, topics, iconSrc, altText }) => {
  if (topics.length === 0) {
    return null;
  }

  return (
    <div className="categories-item">
      <div className="categories-item-img">
        <img src={iconSrc} alt={altText} />
      </div>
      <div className="categories-item__content">
        <span>{category}:</span>
        <ul>
          {topics.map((topic, index) => (
            <li key={index}>{topic}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
