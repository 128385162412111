// CustomerItem.tsx
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import CopyButton from '../CopyButton/CopyButton';
import EditCustomerPopup from '../EditCustomerPopup/EditCustomerPopup';

import manIcon from '../../assets/images/svg/man.svg';
import womanIcon from '../../assets/images/svg/woman.svg';
import locationIcon from '../../assets/images/svg/location.svg';
import editIcon from '../../assets/images/svg/edit.svg';
import openInNewTabIcon from '../../assets/images/open_in_new_tab.png';
import './CustomerItem.css';

interface Props {
  customer: {
    uuid: string;
    customer_name: string;
    customer_number: string;
    call_count: number;
    filtered_call_count?: number;
    last_called_at: Date;
    first_called_at: Date;
    city: string;
    state: string;
    postal_code: string;
    latitude: string;
    longitude: string;
    gender: 'male' | 'female' | 'unknown';
    linked_customer_numbers: { [key: string]: string };
  };
}

export const CustomerItem: React.FC<Props> = ({ customer }) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const location = useLocation();
  const lastCallFormatted = format(customer.last_called_at, 'MMMM do yyyy, h:mma');
  const iconPath = customer.gender === 'male' ? manIcon : womanIcon;

  const handleEditClick = () => {
    setIsEditPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditPopupOpen(false);
  };

  return (
    <div className="patient-card card-wrapper">
      <div className="customer-header">
        <Link to={`/customers/${customer.uuid}`} className="customer-name-link">
          <h2>{customer.customer_name}</h2>
        </Link>
        {location.pathname === '/customers' && (
          <Link 
            to={`/customers/${customer.uuid}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="open-in-new-tab-link"
          >
            <img src={openInNewTabIcon} alt="Open in new tab" />
          </Link>
        )}
        {location.pathname.includes('/customers') && (
        <button onClick={handleEditClick} className="customer-btn-edit">
          <img src={editIcon} alt="edit icon" />
        </button>
      )}
      </div>
      <Link to={`/customers/${customer.uuid}`} rel="noopener noreferrer" className="patient-card__content-link">
        <div className="patient-card__content">
          <div className="patient-card__content-info">
            <img src={iconPath} alt="gender icon" />
            <div className="patient-card__content-text">
              <p>
                <b>Phone:</b>
                <span className="featured-text">{customer.customer_number}</span>&nbsp;
                <CopyButton text={customer.customer_number} />
                &nbsp;&nbsp;&nbsp;
                <b>Total Calls:</b> 
                <span className="featured-text">{customer.call_count}</span>
                {typeof customer.filtered_call_count === 'number' && customer.filtered_call_count > 0 && customer.filtered_call_count !== customer.call_count && (
                  <>
                    &nbsp;&nbsp;&nbsp;
                    <b>Filtered Calls:</b> 
                    <span className="featured-text">{customer.filtered_call_count}</span>
                  </>
                )}
              </p>
              <p>
                <b>Linked Numbers:</b>
                <span className="linked-numbers">
                  {Object.entries(customer.linked_customer_numbers).map(([number, relation]) => (
                    <span key={number} className="linked-number" data-relation={relation}>
                      {number}
                      <CopyButton text={number} />
                    </span>
                  ))}
                </span>
              </p>
              <p><b>Last Call:</b> <span className="featured-text">{lastCallFormatted}</span></p>
            </div>
          </div>
          <div className="patient-card__content-location">
            {customer.city && (
              <>
                <img src={locationIcon} alt="location" />
                <p><span>{customer.city}</span><span>{customer.state}</span></p>
              </>
            )}
          </div>
        </div>
      </Link>
      {isEditPopupOpen && (
        <EditCustomerPopup customer={customer} onClose={handleClosePopup} />
      )}
    </div>
  );
};
