import { client } from '../utils/fetchData';
import { DataCustomersResponse } from '../types/DataCustomersResponse';

export const postCustomersData = async (token: string | null, data: any): Promise<DataCustomersResponse> => {
  return client.post<DataCustomersResponse>('customer_search', token, data);
};

export const updateCustomersData = async (token: string | null, customerUuid: string, data: any): Promise<void> => {
  const url = `customers?customer_uuid=${customerUuid}`;
  await client.put<void>(url, token, data);
};