import React, { useState } from 'react';
import './FilterSidebar.css';

interface FilterSidebarProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const FilterSidebar: React.FC<FilterSidebarProps> = ({ children, isOpen, onClose }) => {
  return (
    <div className={`filter-sidebar-wrapper ${isOpen ? 'open' : ''}`}>
      <div className="filter-sidebar-overlay" onClick={onClose}></div>
      <div className="filter-sidebar-content card-wrapper">
        {children}
      </div>
    </div>
  );
};
