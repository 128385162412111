// src/components/CopyButton.tsx
import React, { useState } from 'react';
import copyIcon from '../../assets/images/copy_icon.png'; // Use the new copy icon
import { copyToClipboard } from '../../utils/copyToClipboard';
import './CopyButton.css';

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop the event from propagating
    copyToClipboard(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Hide acknowledgment after 2 seconds
  };

  return (
    <div className="copy-button-container">
      <button onClick={handleCopy} className="copy-button">
        <img 
          src={copyIcon} 
          alt="Copy to clipboard" 
          style={{ width: '16px', height: '16px' }} // Apply inline styles
        />
      </button>
      {copied && <span className="copy-acknowledgment">Copied!</span>}
    </div>
  );
};

export default CopyButton;
