import React, { useState } from 'react';
import './AdminTable.css';
import searchIcon from '../../assets/images/svg/search.svg';
import addUser from '../../assets/images/svg/add-user.svg';

interface AdminTableProps<T> {
  columns: string[];
  data: T[];
  renderRow: (item: T) => React.ReactNode;
  entityName: string;
  onAdd?: () => void;
  showSearchBar?: boolean;
  onSearch?: (term: string) => void; // Add an optional onSearch prop
}

export const AdminTable = <T extends {}>({
  columns,
  data,
  renderRow,
  entityName,
  onAdd,
  showSearchBar = true,
  onSearch,
}: AdminTableProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (onSearch) {
      onSearch(term); // Pass the search term to the parent component
    }
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      typeof val === 'string' && val.toLowerCase().includes(searchTerm)
    )
  );

  return (
    <div className="admin-table-container">
      <h2>{entityName}</h2>
      <div className="table-controls">
        {showSearchBar && (
          <div className="search-input">
            <div className="search-input__input">
              <input
                type="search"
                onChange={handleSearch}
                placeholder="Enter name ..."
              />
              <img className="search-icon" src={searchIcon} alt="search" />
            </div>
          </div>
        )}

        {onAdd && (
          <button className="cta cta-main" onClick={onAdd}>
            <img src={addUser} alt="add" /> Add {entityName}
          </button>
        )}
      </div>

      <table className="admin-table">
        <thead className="admin-table-header">
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody className="admin-table-body">
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="admin-table-row">
                {renderRow(item)}
              </tr>
              <tr>
                <td colSpan={columns.length}></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
