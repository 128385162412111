import React from 'react';
import { CallList } from '../CallList/CallList';
import { FaqQuery } from '../FaqQuery';
import { LastCallInfo } from '../LastCallInfo';
import { AdmitReviewStats } from '../AdmitReviewStats';
import { DataCallLogResponse } from '../../types/DataCallLogResponse';
import { TextMessagesResponse } from '../../types/TextMessagesResponse';

interface CustomerCallDetailProps {
  callLog: DataCallLogResponse;
  textMessages: TextMessagesResponse | null;
}

export const CustomerCallDetail: React.FC<CustomerCallDetailProps> = ({ callLog, textMessages }) => {
  if (!callLog) return <div>Something went wrong. Please contact the Administrator at pshah@havenhealthmgmt.com</div>;

  return (
    <>
      <div className="last-call">
        <AdmitReviewStats callLog={callLog} />
        <LastCallInfo lastCall={callLog}/>
      </div>
      <div className="faq">
        <FaqQuery question="Entities Recognized" answer={callLog.entities_recognized} />
      </div>
      <div className="faq">
        <FaqQuery question="Conversation Summary" answer={callLog.all_call_summary} />
      </div>
      <div className="faq">
        {textMessages && <FaqQuery question="Text Messages" answer={textMessages} />}
      </div>
      <div>
        <CallList calls={callLog.calls} />
      </div>
    </>
  );
};
