import React, { useState } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { AdminFeature } from '../../types/AdminFeature';
import { useAuth } from '../../contexts/AuthContext';

interface EditFeaturePopupProps {
  feature: AdminFeature;
  onClose: () => void;
  onSave: (updatedFeature: { feature_name: string; updated_by: string }) => void;
}

export const EditFeaturePopup: React.FC<EditFeaturePopupProps> = ({ feature, onClose, onSave }) => {
  const [featureName, setFeatureName] = useState(feature.feature_name);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const handleSave = async () => {
    setError(null);

    if (featureName.trim().length < 3) {
      setError('Feature name must be at least 3 characters long.');
      return;
    }

    setIsLoading(true);
    try {
      const updatedFeature = {
        feature_name: featureName,
        updated_by: user?.email || '',
      };
      await onSave(updatedFeature);
      onClose();
    } catch (err) {
      setError('Failed to save feature');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionPopup
      title="Edit Feature"
      actionType="edit"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={isLoading}
      error={error}
    >
      <div className="form-group">
        <label>Feature Name:</label>
        <input
          type="text"
          value={featureName}
          onChange={(e) => setFeatureName(e.target.value)}
          placeholder="Enter at least 3 characters"
        />
      </div>
    </ActionPopup>
  );
};
