import React, { useEffect, useRef } from 'react';
import './ActionPopup.css';
import closeIcon from '../../assets/images/svg/close-dark.svg';

interface ActionPopupProps {
  title: string;
  actionType: 'add' | 'edit' | 'delete';
  children?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  error: string | null;
}

export const ActionPopup: React.FC<ActionPopupProps> = ({
  title,
  actionType,
  children,
  onClose,
  onConfirm,
  isLoading,
  error,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="popup-overlay">
      <div className="popup-overlay-content card-wrapper" ref={popupRef}>
        <img src={closeIcon} alt="Close" className="close-icon" onClick={onClose} />
        <h3>{title}</h3>
        {actionType === 'delete' ? (
          <p>Are you sure you want to delete this item?</p>
        ) : (
          <form>
            {children}
          </form>
        )}
        <div className="form-actions">
          <button className='cta cta-secondary' type="button" onClick={onClose}>
            Cancel
          </button>
          <button
            className={`cta cta-${actionType === 'delete' ? 'danger' : 'main'}`}
            type="button"
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : actionType === 'delete' ? 'Delete' : 'Save'}
          </button>
        </div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};
