import { client } from '../utils/fetchData';
import { AdminRoleFeature } from '../types/AdminRoleFeature';

export const getRoleFeatureLinkageData = async (token: string | null): Promise<AdminRoleFeature[]> => {
  return client.get<AdminRoleFeature[]>('role-feature-linkage', token);
};

export const updateRoleFeatureAccess = async (
  token: string | null,
  roleId: number,
  updatedRoleFeature: { feature_id: number; can_access: boolean; created_by?: string; updated_by?: string }
): Promise<AdminRoleFeature> => {
  return client.put(`roles/${roleId}/features`, token, updatedRoleFeature) as Promise<AdminRoleFeature>;
};