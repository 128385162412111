import { client } from '../utils/fetchData';
import { AdminUser } from '../types/AdminUser';

export const getAdminUsersData = async (token: string | null): Promise<AdminUser[]> => {
  return client.get<AdminUser[]>('users', token);
};

export const updateUserActiveStatus = async (token: string | null, userId: number, isActive: boolean): Promise<AdminUser> => {
  return client.put<AdminUser>(`users/${userId}/active`, token, { is_active: isActive });
};

export const updateAdminUserData = async (userId: number, updatedUserData: { name: string; role_id: number; updated_by: string }, token: string): Promise<AdminUser> => {
  return client.put<AdminUser>(`users/${userId}`, token, updatedUserData);
};

export const addAdminUserData = async (newUserData: { email: string; name: string; role_id: number; created_by: string }, token: string): Promise<AdminUser> => {
  return client.post<AdminUser>('users', token, newUserData);
};