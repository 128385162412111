import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAgentContext } from '../../contexts/AgentContext';
import { useFiltersContext } from '../../contexts/FilterContext';
import { getAgentCallsData } from '../../api/agentCalls';
import { Loader } from '../Loader';
import linkImg from '../../assets/images/svg/quill_link-out.svg';
import { AgentCallLog } from '../../types/AgentCallLog';
import { useAuth } from '../../contexts/AuthContext';
import './AgentCallsTable.css';
import inboundCall from '../../assets/images/svg/inbound.svg';
import outboundCall from '../../assets/images/svg/outbound.svg';
import { AgentCallCard } from '../AgentCallCard';
import { CSSTransition } from 'react-transition-group';
import { Call } from '../../types/Call';
import ascIcon from '../../assets/images/svg/asc-sort.svg';
import descIcon from '../../assets/images/svg/desc-sort.svg';
import { Pagination } from '../Pagination';
import { tr } from 'date-fns/locale';

export const AgentCallsTable: React.FC<{ agentId: string }> = ({ agentId }) => {
  const { filters } = useFiltersContext();
  const [calls, setCalls] = useState<AgentCallLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeCallId, setActiveCallId] = useState<string | null>(null);
  const [callDetails, setCallDetails] = useState<Call | null>(null);
  const [sortBy, setSortBy] = useState<string>('campaign');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const { userToken } = useAuth();
  const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});
  const nodeRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    nextPageUrl: null as string | null,
    prevPageUrl: null as string | null,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const isInitialLoad = useRef(true); 

  const fetchCalls = useCallback(async () => {
    setLoading(true);
    if (!userToken) {
      console.log('User token is not available.');
      return;
    }
    try {
      const data = await getAgentCallsData(userToken, agentId, filters, { currentPage: pagination.currentPage, itemsPerPage }, sortBy, sortOrder);
      setCalls(data.call_entries);
      setPagination({
        currentPage: data.page,
        totalPages: data.total_pages,
        total: data.total,
        nextPageUrl: data.next_page_url,
        prevPageUrl: data.prev_page_url,
      });
    } catch (error) {
      console.error('Error fetching agent calls:', error);
    } finally {
      setLoading(false);
    }
  }, [agentId, filters, pagination.currentPage, itemsPerPage, sortBy, sortOrder, setPagination, userToken]);
  

  const fetchCallDetails = useCallback(async (callId: string) => {
    setLoading(true);
    if (!userToken) {
      console.log('User token is not available.');
      return;
    }
    try {
      const response = await fetch(`https://api.hotanalytics.ai/api/v1/call_details/${callId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.ok) {
        console.error(`Error fetching call details: ${response.status} - ${response.statusText}`);
        return;
      }

      const responseText = await response.text();

      try {
        const data = JSON.parse(responseText);
        setCallDetails(data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

    } catch (error) {
      console.error('Error fetching call details:', error);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  const handleCallClick = (callId: string) => {
    if (callId === activeCallId) {
      setActiveCallId(null);
      setCallDetails(null);
    } else {
      setActiveCallId(callId);
      fetchCallDetails(callId);

      // Scroll into view
      if (rowRefs.current[callId]) {
        rowRefs.current[callId]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };


  const handleSortClick = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const nextPage = () => {
    if (pagination.nextPageUrl) {
      setPagination((prev) => ({
        ...prev, // Spread the previous state to maintain existing fields
        currentPage: prev.currentPage + 1, // Increment the current page
      }));
      fetchCalls(); // Fetch the new data after updating the pagination
    }
  };
  
  const prevPage = () => {
    if (pagination.prevPageUrl) {
      setPagination((prev) => ({
        ...prev, // Spread the previous state
        currentPage: prev.currentPage - 1, // Decrement the current page
      }));
      fetchCalls(); // Fetch the new data after updating the pagination
    }
  };

  useEffect(() => {
    fetchCalls();
  }, [fetchCalls, pagination.currentPage, itemsPerPage, sortBy, sortOrder]);

  if (loading) return <Loader />;

  return (
    <>
    <Pagination
        pagination={pagination}
        nextPage={nextPage}
        prevPage={prevPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        searchItems={fetchCalls}
        itemsCount={calls.length}
      />
      <table className="calls-table">
     <thead className="calls-table-header">
        <tr>
          <th onClick={() => handleSortClick('call_id')}>
            <div className='sort-container'>
            Call ID
            {sortBy === 'call_id' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('called_at')}>
          <div className='sort-container'>
            Date 
            {sortBy === 'called_at' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('customer_name')}>
          <div className='sort-container'>
            Customer Name 
            {sortBy === 'customer_name' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('customer_ph_number')}>
          <div className='sort-container'>
          <div className='sort-container'>
            Customer Number
            {sortBy === 'customer_ph_number' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
            </div>
          </th>
          <th onClick={() => handleSortClick('agent_ph_number')}>
          <div className='sort-container'>
            Agent Number 
            {sortBy === 'agent_ph_number' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('campaign')}>
          <div className='sort-container'>
            Campaign 
            {sortBy === 'campaign' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('source')}>
          <div className='sort-container'>
            Source 
            {sortBy === 'source' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('keyword')}>
          <div className='sort-container'>
            Keyword
            {sortBy === 'keyword' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th onClick={() => handleSortClick('importance_score')}>
          <div className='sort-container'>
            Importance Score
            {sortBy === 'importance_score' && (
              <img src={sortOrder === 'asc' ? ascIcon : descIcon} alt={sortOrder === 'asc' ? 'Ascending' : 'Descending'} className="sort-icon" />
            )}
            </div>
          </th>
          <th>SF URL</th>
        </tr>
      </thead>
      <tbody className="calls-table-body">
      {loading && !isInitialLoad.current ? (  // Show loader during subsequent loads
            <tr>
              <td colSpan={10}>
                <Loader />  {/* Show Loader inside tbody */}
              </td>
            </tr>
          ) : (
            <>
            <tr>
              <td colSpan={10}></td>
            </tr>
              {calls.map((call) => {
                const callDirection = call.direction.toLowerCase();
                const isInbound = callDirection === 'inbound';
                const callIcon = isInbound ? inboundCall : outboundCall;
                const bgColor = isInbound ? 'var(--inbound-bg)' : 'var(--outbound-bg)';

                return (
                 
                  <React.Fragment key={call.call_id}>
                    <tr
                      ref={(el) => (rowRefs.current[call.call_id] = el)}
                      className={`calls-table-row ${activeCallId === call.call_id ? 'active' : ''}`}
                      onClick={() => handleCallClick(call.call_id)}
                    >
                      <td>
                        <span className="calls-table-row__info">
                          <span className="calls-table-row__info-icon" style={{ backgroundColor: bgColor }} data-alt={callDirection}>
                            <img src={callIcon} alt={callDirection} />
                          </span>
                          #{call.call_id}
                        </span>
                      </td>
                      <td>{new Date(call.called_at).toLocaleString()}</td>
                      <td>{call.customer_name}</td>
                      <td>{call.customer_ph_number}</td>
                      <td>{call.agent_ph_number}</td>
                      <td>{call.campaign}</td>
                      <td>{call.source}</td>
                      <td>{call.keyword}</td>
                      <td>{call.importance_score === '-1' ? 'N/A' : `${call.importance_score}`}</td>
                      <td>
                        <a href={call.salesforce_url} target="_blank" rel="noopener noreferrer">
                          <img src={linkImg} alt="link-icon" />
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={10}>
                        <CSSTransition
                          in={activeCallId === call.call_id}
                          timeout={500}
                          classNames="slide"
                          unmountOnExit
                          nodeRef={nodeRefs.current[call.call_id]}
                        >
                          <div ref={nodeRefs.current[call.call_id]} className="call-details">
                            {loading ? <Loader /> : callDetails && <AgentCallCard call={callDetails} onPlay={() => {}} />}
                          </div>
                        </CSSTransition>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
