import React from 'react';
import { useAgentContext } from '../../contexts/AgentContext';
import arrowGreenUp from '../../assets/images/svg/Arrow-green-up.svg';
import arrowGreenDown from '../../assets/images/svg/Arrow-green-down.svg';
import arrowRedUp from '../../assets/images/svg/Arrow-red-up.svg';
import arrowRedDown from '../../assets/images/svg/Arrow-red-down.svg';

type KPIKey = 'inbound' | 'outbound' | 'inbound_message' | 'outbound_message' | 
              'active_days';

interface KPI {
  label: string;
  key: KPIKey;
  format?: boolean;
}

const timeStringToSeconds = (timeString: string): number => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return (hours * 3600) + (minutes * 60) + seconds;
};

const secondsToTimeString = (totalSeconds: number): string => {
  return `${totalSeconds.toFixed(1)}s`;
};

const calculateTimeDifference = (current: number, previous: number): string => {
  const diff = current - previous;
  if (diff > 0) {
    return `${diff.toFixed(1)} seconds more than last month`;
  } else {
    return `${Math.abs(diff).toFixed(1)} seconds less than last month`;
  }
};

const calculatePercentageDifference = (current: number, previous: number) => {
  if (previous === 0) return 'New data';
  const diff = ((current - previous) / previous) * 100;
  return diff > 0
    ? `${Math.abs(diff).toFixed(1)}% up since last month`
    : `${Math.abs(diff).toFixed(1)}% down since last month`;
};

const calculateAverageTime = (inboundTime: string, outboundTime: string, inboundCalls: number, outboundCalls: number): number => {
  const totalTimeInSeconds = timeStringToSeconds(inboundTime) + timeStringToSeconds(outboundTime);
  const totalCalls = inboundCalls + outboundCalls;
  if (totalCalls === 0) return 0;
  return totalTimeInSeconds / totalCalls;
};

const getArrowForPercentage = (performance: string) => {
  return performance.includes('up') ? arrowGreenUp : arrowRedDown;
};

const getArrowForTime = (performance: string) => {
  return performance.includes('more') ? arrowRedUp : arrowGreenDown;
};

const getArrowForTalkTime = (performance: string) => {
  return performance.includes('more') ? arrowGreenUp : arrowRedDown;
};

export const AgentPerformanceTable: React.FC = () => {
  const { selectedAgent } = useAgentContext();

  if (!selectedAgent || !selectedAgent.this_month || !selectedAgent.last_month) {
    return <div>No performance data available.</div>;
  }

  const { this_month, last_month } = selectedAgent;

  const kpis: KPI[] = [
    { label: 'Inbound Calls', key: 'inbound' },
    { label: 'Outbound Calls', key: 'outbound' },
    { label: 'Inbound Messages', key: 'inbound_message' },
    { label: 'Outbound Messages', key: 'outbound_message' },
    { label: 'Active Days', key: 'active_days' }
  ];

  const avgHoldTimeLastMonth = calculateAverageTime(last_month.inbound_hold_time_mins, last_month.outbound_hold_time_mins, last_month.inbound, last_month.outbound);
  const avgHoldTimeThisMonth = calculateAverageTime(this_month.inbound_hold_time_mins, this_month.outbound_hold_time_mins, this_month.inbound, this_month.outbound);
  const holdTimeComparison = calculateTimeDifference(avgHoldTimeThisMonth, avgHoldTimeLastMonth);

  const avgWaitTimeLastMonth = calculateAverageTime(last_month.inbound_wait_time_mins, last_month.outbound_wait_time_mins, last_month.inbound, last_month.outbound);
  const avgWaitTimeThisMonth = calculateAverageTime(this_month.inbound_wait_time_mins, this_month.outbound_wait_time_mins, this_month.inbound, this_month.outbound);
  const waitTimeComparison = calculateTimeDifference(avgWaitTimeThisMonth, avgWaitTimeLastMonth);

  const avgRingTimeLastMonth = calculateAverageTime(last_month.inbound_ring_time_mins, last_month.outbound_ring_time_mins, last_month.inbound, last_month.outbound);
  const avgRingTimeThisMonth = calculateAverageTime(this_month.inbound_ring_time_mins, this_month.outbound_ring_time_mins, this_month.inbound, this_month.outbound);
  const ringTimeComparison = calculateTimeDifference(avgRingTimeThisMonth, avgRingTimeLastMonth);

  const avgTalkTimeLastMonth = last_month.average_talk_time;
  const avgTalkTimeThisMonth = this_month.average_talk_time;
  const talkTimeComparison = calculateTimeDifference(avgTalkTimeThisMonth, avgTalkTimeLastMonth);

  return (
    <div className="table-wrapper">
      <table className="styled-table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>{last_month.month}</th>
            <th>{this_month.month}</th>
            <th>Performance</th>
          </tr>
        </thead>
        <tbody>
          {kpis.map(kpi => {
            const lastValue = last_month[kpi.key];
            const thisValue = this_month[kpi.key];

            const performance = typeof lastValue === 'number' && typeof thisValue === 'number'
              ? calculatePercentageDifference(thisValue, lastValue)
              : 'N/A';

            const arrow = getArrowForPercentage(performance);

            return (
              <tr key={kpi.key}>
                <td>{kpi.label}</td>
                <td>{lastValue}</td>
                <td>{thisValue}</td>
                <td>
                  <img src={arrow} alt="performance arrow" /> {performance}
                </td>
              </tr>
            );
          })}

          <tr>
            <td>Average Talk Time</td>
            <td>{avgTalkTimeLastMonth.toFixed(1)}s</td>
            <td>{avgTalkTimeThisMonth.toFixed(1)}s</td>
            <td>
              <img src={getArrowForTalkTime(talkTimeComparison)} alt="performance arrow" /> {talkTimeComparison}
            </td>
          </tr>
          <tr>
            <td>Average Hold Time</td>
            <td>{secondsToTimeString(avgHoldTimeLastMonth)}</td>
            <td>{secondsToTimeString(avgHoldTimeThisMonth)}</td>
            <td>
              <img src={getArrowForTime(holdTimeComparison)} alt="performance arrow" /> {holdTimeComparison}
            </td>
          </tr>
          <tr>
            <td>Average Wait Time</td>
            <td>{secondsToTimeString(avgWaitTimeLastMonth)}</td>
            <td>{secondsToTimeString(avgWaitTimeThisMonth)}</td>
            <td>
              <img src={getArrowForTime(waitTimeComparison)} alt="performance arrow" /> {waitTimeComparison}
            </td>
          </tr>
          <tr>
            <td>Average Ring Time</td>
            <td>{secondsToTimeString(avgRingTimeLastMonth)}</td>
            <td>{secondsToTimeString(avgRingTimeThisMonth)}</td>
            <td>
              <img src={getArrowForTime(ringTimeComparison)} alt="performance arrow" /> {ringTimeComparison}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AgentPerformanceTable;
