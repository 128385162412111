import { User } from "../types/User";
import { BASE_URL } from '../config';

// Helper function to handle the final URL properly
const constructUrl = (endpoint: string) => {
  return BASE_URL.endsWith('/') ? `${BASE_URL}${endpoint}` : `${BASE_URL}/${endpoint}`;
};

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

// Generic fetch function for data retrieval with Authorization header
export const fetchData = async <T>(url: string, token: string | null = null): Promise<T> => {
  try {
    const headers: HeadersInit = {
      'Accept': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await fetch(constructUrl(url), {
      method: 'GET',
      headers,
      credentials: token ? 'include' : 'omit',  // Use 'include' if token exists, otherwise omit
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}: ${response.status} ${response.statusText}`);
    }

    const data = await response.json() as T;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Specific function to fetch allowed users
export const fetchAllowedUsers = async (url: string): Promise<User[]> => {
  return fetchData<User[]>(url);
};

// Generic request function
async function request<T>(url: string, method: RequestMethod = 'GET', token: string | null = '', data: any = null): Promise<T> {
  const headers: HeadersInit = {
    'Accept': 'application/json'
  };

  // Add Authorization header if token is available
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

   // Set Content-Type header if the request is not sending FormData
  if (data && !(data instanceof FormData)) {
    headers['Content-Type'] = 'application/json; charset=UTF-8';
  }

  const options: RequestInit = {
    method,
    headers,
    credentials: token ? 'include' : 'omit',  // Only include credentials if a token exists
  };

  if (data) {
    options.body = data instanceof FormData ? data : JSON.stringify(data);
  }

  const response = await fetch(constructUrl(url), options);

  if (!response.ok) {
    const errorText = await response.text();
    console.error('Error response:', errorText);
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }

  return await response.json();
}

export const client = {
  get: <T>(url: string, token: string | null = '') => request<T>(url, 'GET', token),
  post: <T>(url: string, token: string | null = '', data: any) => request<T>(url, 'POST', token, data),
  put: <T>(url: string, token: string | null = '', data: any) => request<T>(url, 'PUT', token, data),
};
