import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { addAdminRoleData, getAdminRolesData, updateAdminRoleData } from '../api/roles';
import { AdminRole } from '../types/AdminRole';

export const useAdminRoles = () => {
  const [roles, setRoles] = useState<AdminRole[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchRoles = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getAdminRolesData(userToken);
      setRoles(response);
    } catch (err) {
      setError('Failed to fetch roles.');
      console.error('Error fetching roles:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  const addRole = async (newRoleData: { role_name: string; is_super_admin: boolean; created_by: string }) => {
    if (!userToken) return;

    try {
      const newRole = await addAdminRoleData(userToken, newRoleData);
      setRoles((prevRoles) => [...(prevRoles ?? []), newRole]);
    } catch (err) {
      console.error('Error adding role:', err);
      throw err;
    }
  };

  const editRole = async (roleId: number, updatedRoleData: { role_name: string; is_super_admin: boolean; updated_by: string }) => {
    if (!userToken) return;

    try {
      const updatedRole = await updateAdminRoleData(userToken, roleId, updatedRoleData);
      setRoles((prevRoles) => prevRoles?.map(role => role.id === roleId ? updatedRole : role) ?? []);
    } catch (err) {
      console.error('Error updating role:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return { roles, addRole, editRole, loading, error };
};
