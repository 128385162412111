import { client } from '../utils/fetchData';
import { DataAgentCallsResponse } from '../types/DataAgentCallsResponse';
import { Filters } from '../types/Filters';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';

const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

export const getAgentCallsData = async (
  token: string, 
  agentId: string, 
  filters: Filters, 
  pagination: { currentPage: number, itemsPerPage: number },
  sort_by: string,
  order: string
): Promise<DataAgentCallsResponse> => {
  const params: { [key: string]: any } = {
    agent_id: agentId,
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
    cities: filters.cities,
    states: filters.states,
    postal_codes: filters.postalCodes,
    campaigns: filters.campaign,
    sources: filters.source,
    keywords: filters.keyword,
    page: pagination.currentPage,
    per_page: pagination.itemsPerPage,
    content: 'full',
    sort_by,
    order,
  };

  return client.post<DataAgentCallsResponse>(`agent_calls/?sort_by=${sort_by}&order=${order}`, token, params);
};