import { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { postCustomersData } from '../api/customers';
import { DataCustomersResponse } from '../types/DataCustomersResponse';

interface UseCustomerProps {
  searchParams: {};
}

export const useCustomers = ({ searchParams: searchParamsProp }: UseCustomerProps) => {
  const [customers, setCustomers] = useState<DataCustomersResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const searchParams = useMemo(() => searchParamsProp, [searchParamsProp]);

  const fetchCustomersData = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    if (!searchParams || Object.keys(searchParams).length === 0) {
      setError('Search parameters are empty. Cannot fetch customers.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await postCustomersData(userToken, searchParams);
      setCustomers(response);
    } catch (err) {
      setError('Failed to fetch customer data.');
      console.error('Error fetching customer data:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, searchParams]);
  
  useEffect(() => {
    fetchCustomersData();
  }, [fetchCustomersData]);

  return { customers, loading, error };
};
