import React from 'react';

const NotAuthorizedPage: React.FC = () => {
  return (
    <div className='container'>
      <h1>403 - Not Authorized</h1>
      <p>You do not have permission to view this page.</p>
      <p>Please contact the Administrator at <span className='featured-text'>pshah@havenhealthmgmt.com</span></p>
    </div>
  );
};

export default NotAuthorizedPage;
