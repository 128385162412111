import React, { useState } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { useAuth } from '../../contexts/AuthContext';

interface AddRolePopupProps {
  onClose: () => void;
  onSave: (newRole: { role_name: string; is_super_admin: boolean; created_by: string }) => void;
}

export const AddRolePopup: React.FC<AddRolePopupProps> = ({ onClose, onSave }) => {
  const [roleName, setRoleName] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const handleSave = async () => {
    setError(null);

    if (roleName.trim().length < 3) {
      setError('Role name must be at least 3 characters long.');
      return;
    }

    setIsLoading(true);
    try {
      const newRole = {
        role_name: roleName,
        is_super_admin: isSuperAdmin,
        created_by: user?.email || '',
      };
      await onSave(newRole);
      onClose();
    } catch (err) {
      setError('Failed to add role');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionPopup
      title="Add New Role"
      actionType="add"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={isLoading}
      error={error}
    >
      <div className="form-group">
        <label>Role Name:</label>
        <input
          type="text"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          placeholder="Enter at least 3 characters"
        />
      </div>
      <div className="form-group">
        <input
          id="super-admin-checkbox"
          type="checkbox"
          checked={isSuperAdmin}
          onChange={(e) => setIsSuperAdmin(e.target.checked)}
        />
        <label htmlFor="super-admin-checkbox">Super Admin</label>
      </div>
    </ActionPopup>
  );
};
