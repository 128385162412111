import React from 'react';
import totalCalls from '../../assets/images/svg/stats/total-calls.svg';
import totalMessages from '../../assets/images/svg/stats/total-mes.svg';
import totalTalk from '../../assets/images/svg/stats/total-talk.svg';
import avgWaitTime from '../../assets/images/svg/stats/avg-wait-time.svg';
import { useOverallStats } from '../../hooks/useOverallStats';
import { formatNumberWithCommas, parseAndSumTimeStrings, formatTimeFromMinutes } from '../../utils/statsFormatting';
import './AgentStatsList.css';
import { useFiltersContext } from '../../contexts/FilterContext';
import { format, toDate } from 'date-fns';

const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");


export const AgentStatsList: React.FC = () => {
  const { filters } = useFiltersContext();
  const { overallStats } = useOverallStats({
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
  });

  if (!overallStats) return null;

  const statsData = [
    {
      iconSrc: totalCalls,
      altText: 'total calls icon',
      label: 'Total Calls',
      value: formatNumberWithCommas(overallStats.total_inbound + overallStats.total_outbound),
      itemBgColor: 'rgba(246, 163, 90, 0.28)',
      iconBgColor: '#F6A35A',
    },
    {
      iconSrc: totalMessages,
      altText: 'total messages icon',
      label: 'Total Messages',
      value: formatNumberWithCommas(overallStats.total_inbound_message + overallStats.total_outbound_message),
      itemBgColor: 'rgba(246, 198, 96, 0.28)',
      iconBgColor: '#F6C660',
    },
    {
      iconSrc: totalTalk,
      altText: 'total talk icon',
      label: 'Total Talk Time',
      value: parseAndSumTimeStrings(overallStats.total_inbound_talktime_mins, overallStats.total_outbound_talktime_mins),
      itemBgColor: 'rgba(91, 198, 202, 0.23)',
      iconBgColor: '#5BC6CA',
    },
    {
      iconSrc: avgWaitTime,
      altText: 'avg wait time icon',
      label: 'Avg Wait Time',
      value: formatTimeFromMinutes(overallStats.total_average_time),
      itemBgColor: 'rgba(206, 212, 215, 0.81)',
      iconBgColor: '#A4AAAD',
    },
  ];

  return (
    <div className="overall-stats">
      {statsData.map((stat, index) => (
        <div
          key={index}
          className="overall-stats__item"
          style={{ backgroundColor: stat.itemBgColor }}
        >
          <div
            className="overall-stats__item-icon"
            style={{ backgroundColor: stat.iconBgColor }}
          >
            <img src={stat.iconSrc} alt={stat.altText} />
          </div>
          <div className="overall-stats__item-content">
            <p>{stat.label}</p>
            <h3>{stat.value}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgentStatsList;
