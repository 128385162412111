import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getCampaignData } from '../api/campaign';
import { DataCampaignResponse } from '../types/DataCampaignResponse';

export const useCampaignData = () => {
  const [campaign, setCampaign] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();

  const fetchCampaign = async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataCampaignResponse = await getCampaignData(userToken);
      setCampaign(response.campaign);
    } catch (err) {
      console.log('Error fetching cities:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchCampaign();
    }
  }, []);
  return { campaign, loading };
};