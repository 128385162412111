import React, { useState } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { AdminRole } from '../../types/AdminRole';
import { useAuth } from '../../contexts/AuthContext';

interface EditRolePopupProps {
  role: AdminRole;
  onClose: () => void;
  onSave: (updatedRole: { role_name: string; is_super_admin: boolean; updated_by: string }) => void;
}

export const EditRolePopup: React.FC<EditRolePopupProps> = ({ role, onClose, onSave }) => {
  const [roleName, setRoleName] = useState(role.role_name);
  const [isSuperAdmin, setIsSuperAdmin] = useState(role.is_super_admin);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const handleSave = async () => {
    setError(null); // Clear any previous error

    // Validation: Role name should be at least 3 characters long
    if (roleName.trim().length < 3) {
      setError('Role name must be at least 3 characters long.');
      return;
    }

    setIsLoading(true);
    try {
      const updatedRole = {
        role_name: roleName,
        is_super_admin: isSuperAdmin,
        updated_by: user?.email || '',
      };
      await onSave(updatedRole);
      onClose();
    } catch (err) {
      setError('Failed to save role');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionPopup
      title="Edit Role"
      actionType="edit"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={isLoading}
      error={error}
    >
      <div className="form-group">
        <label>Role Name:</label>
        <input
          type="text"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          placeholder="Enter at least 3 characters"
        />
      </div>
      <div className="form-group">
        <input
          id="super-admin-checkbox"
          type="checkbox"
          checked={isSuperAdmin}
          onChange={(e) => setIsSuperAdmin(e.target.checked)}
        />
        <label htmlFor='super-admin-checkbox'>Super Admin:</label>
      </div>
    </ActionPopup>
  );
};
