import React from 'react';
import './ToggleSwitch.css'; // Import the CSS file

interface ToggleSwitchProps {
  isChecked: boolean;
  onToggle: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isChecked, onToggle }) => {
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(); // Call the parent onToggle function
  };

  return (
      <input
        className="apple-switch"
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
  );
};

export default ToggleSwitch;
