import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { client } from '../../utils/fetchData';
import ReactMarkdown from 'react-markdown';
import './FloatingChat.css';
import chatIcon from '../../assets/images/svg/chat.svg';
import closeIcon from '../../assets/images/svg/close.svg';
import fullScreenIcon from '../../assets/images/svg/fullscreen.svg';
import Slider from '../Slider/Slider';

interface ChatEntry {
  message: string;
  response: string;
  timestamp: string;
}

interface ChatResponse {
  customer_uuid: string;
  user_email: string;
  chat_history: ChatEntry[];
}

interface FloatingChatProps {
  customerUuid: string;
}

const FloatingChat: React.FC<FloatingChatProps> = ({ customerUuid }) => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatEntry[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false); 
  const [activeSuggestion, setActiveSuggestion] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const userToken = localStorage.getItem('token');

  const fetchChatHistory = async () => {
    setIsFetching(true);
    try {
      const response: ChatResponse = await client.post<ChatResponse>(
        'chat',
        userToken,
        { customer_uuid: customerUuid, message: '' }
      );
      setChatHistory(response.chat_history);
    } catch (err) {
      console.log(err);
      setError('Failed to fetch chat history.');
      setChatHistory([{ message: 'Failed to fetch chat history.', response: '', timestamp: new Date().toISOString() }]);
      setTimeout(() => setError(null), 3000); // Hide error message after 3000ms
    } finally {
      setIsFetching(false);
      setIsSending(false);
      setIsClearing(false);
      setActiveSuggestion(null);
    }
  };

  useEffect(() => {
    if (customerUuid) {
      fetchChatHistory();
    }
  }, [customerUuid]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleSendMessage = async (customMessage: string | null = null) => {
    const msg = customMessage !== null ? customMessage : message;
    if (!msg.trim()) return;
    setIsSending(true);
    try {
      const response: ChatResponse = await client.post<ChatResponse>(
        'chat',
        userToken,
        { customer_uuid: customerUuid, message: msg }
      );
      setChatHistory(response.chat_history);
      setMessage('');
      setActiveSuggestion(null); // Clear active suggestion after sending the message
    } catch (err) {
      setError('Error sending message');
      setTimeout(() => setError(null), 3000); // Hide error message after 3000ms
    } finally {
      setIsSending(false);
    }
  };

  const handleClearChat = async () => {
    setIsClearing(true);
    try {
      await client.put(
        `clearChat?customer_uuid=${customerUuid}`,
        userToken,
        {} // Put request usually requires a body, even if empty
      );
      // Clear the chat history immediately
      setChatHistory([]);
      setIsClearing(false);
    } catch (err) {
      setError('Error clearing chat history');
      setTimeout(() => setError(null), 3000); // Hide error message after 3000ms
      setIsClearing(false);
    }
  };

  const handleSuggestionClick = async (suggestion: string) => {
    setActiveSuggestion(suggestion);
    await handleSendMessage(suggestion);
  };

  const toggleChat = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const closeChat = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const toggleFullscreen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      if (isFullscreen) {
        chatContainerRef.current.style.width = '100vw';
        chatContainerRef.current.style.height = '100vh';
        chatContainerRef.current.style.top = '0';
        chatContainerRef.current.style.left = '0';
      } else {
        chatContainerRef.current.style.width = '';
        chatContainerRef.current.style.height = '';
        chatContainerRef.current.style.top = '';
        chatContainerRef.current.style.left = '';
      }
    }
  }, [isFullscreen]);

  return (
    <>
      <div
        className={classNames("floating-chat-icon", { closed: isOpen })}
        onClick={toggleChat}
      >
        <div className="expand-btn expand-left">
          <div role="button" className="expand-icon"><img src={chatIcon} alt="" /></div>
          <span className="expand-text">Ask Me</span>
        </div>
      </div>
      <div ref={chatContainerRef} className={classNames("floating-chat", { closed: !isOpen, fullscreen: isFullscreen })}>
        <div onClick={toggleChat}>
          <div className='chat-header'>
            Ask AI
           <div className="chat-header-icons">
           <img src={fullScreenIcon} alt="Fullscreen" className="fullscreen-icon" onClick={toggleFullscreen} />
           <img src={closeIcon} alt="Close" className="close-icon" onClick={closeChat} />
           </div>
          </div>
        </div>
        {isOpen && (
          <div className="chat-body" onClick={(e) => e.stopPropagation()}>
            <div className="chat-history" ref={chatBodyRef}>
              {isFetching && (
                <div className="welcome-message">
                  Hi, I am your personal assistant. Feel free to ask me questions related to customers' travel, accommodation, insurance, etc. 
                </div>
              )}
              {!isFetching && chatHistory.length === 0 && (
                <div className="welcome-message">
                  {isClearing ? 'Clearing chat...' : 'Hi, I am your personal assistant. Feel free to ask me questions related to customers\' travel, accommodation, insurance, etc.'}
                </div>
              )}
              {chatHistory.map((entry, index) => (
                <div key={index} className="chat-entry">
                  <p className="chat-message"><span className='featured-text'>Q:</span> {entry.message}</p>
                  <div className="chat-response"><ReactMarkdown>{entry.response}</ReactMarkdown></div>
                  <p className="chat-timestamp">{new Date(entry.timestamp).toLocaleString()}</p>
                </div>
              ))}
            </div>
            {error && <p className="error-message">{error}</p>}
            <div className="chat-input-container">
              <div className="chat-input-area">
                <div className='chat-input'>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    className=""
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  />
                </div>
                <div className="chat-btn-container">
                  <button
                    onClick={() => handleSendMessage()}
                    className="cta cta-main"
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  >
                    {isSending ? 'Sending...' : 'Send'}
                  </button>
                  <button
                    onClick={handleClearChat}
                    className="cta cta-secondary"
                    disabled={isClearing || isSending || activeSuggestion !== null}
                  >
                    {isClearing ? 'Clearing...' : 'Clear'}
                  </button>
                </div>
              </div>
              
              <div className="chat-suggestions">
                <Slider>
                  <button
                    className={`chat-suggestions__item ${activeSuggestion === 'Generate QA Audit' ? 'active' : ''}`} 
                    onClick={() => handleSuggestionClick('Generate QA Audit')}
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  >
                    Generate QA Audit
                  </button>
                  <button 
                    className={`chat-suggestions__item ${activeSuggestion === 'Check Performance' ? 'active' : ''}`} 
                    onClick={() => handleSuggestionClick('Check Performance')}
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  >
                    Check Performance
                  </button>
                  <button 
                    className={`chat-suggestions__item ${activeSuggestion === 'Next Steps on interaction' ? 'active' : ''}`} 
                    onClick={() => handleSuggestionClick('Next Steps on interaction')}
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  >
                    Next Steps on interaction
                  </button>
                  <button 
                    className={`chat-suggestions__item ${activeSuggestion === 'Marketing Insights' ? 'active' : ''}`} 
                    onClick={() => handleSuggestionClick('Marketing Insights')}
                    disabled={isSending || isClearing || activeSuggestion !== null}
                  >
                    Marketing Insights
                  </button>
                </Slider>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FloatingChat;
