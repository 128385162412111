import React, { useState, useRef, useEffect } from 'react';
import './Dropdown.css';
import arrowUp from '../../assets/images/svg/arrow-up.svg';
import arrowDown from '../../assets/images/svg/arrow-down.svg';

interface DropdownProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  renderHeader: () => React.ReactNode;
  renderBody: (isOpen: boolean, handleOptionClick: (value: string) => void) => React.ReactNode;
  className?: string;
  closeOnSelect?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  value,
  onChange,
  renderHeader,
  renderBody,
  className = '',
  closeOnSelect = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    if (closeOnSelect) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`dropdown ${className}`}
      ref={dropdownRef}
      // onMouseLeave={() => setIsOpen(false)}
    >
      <div className="dropdown-header" onClick={handleToggle}>
        <div className="header-content">
          {renderHeader()}
        </div>
        <span className="arrow">
          {isOpen ? <img src={arrowUp} alt="arrow up" /> : <img src={arrowDown} alt="arrow down" />}
        </span>
      </div>
      <div className={`dropdown-body ${isOpen ? 'open' : ''}`}>
        {renderBody(isOpen, handleOptionClick)}
      </div>
    </div>
  );
};

