import React, { useState, useEffect } from 'react';
import { AdminTable } from '../AdminTable';
import { AdminFeature } from '../../types/AdminFeature';
import { formatDateTime } from '../../utils/formatDateTime';
import { EditFeaturePopup } from '../EditFeaturePopup/EditFeaturePopup';
import { AddFeaturePopup } from '../AddFeaturePopup/AddFeaturePopup';
import { useAdminFeatures } from '../../hooks/useAdminFeatures'; // Hook to fetch features
import { ReactComponent as EditIcon } from '../../assets/images/svg/edit-user1.svg';
import { ToggleSwitch } from '../ToggleSwitch';
import { Loader } from '../Loader';

export const FeaturesTable: React.FC = () => {
  const { features, loading, error, toggleFeatureActiveState, addFeature, editFeature } = useAdminFeatures();
  const [editingFeature, setEditingFeature] = useState<AdminFeature | null>(null);
  const [isAddFeaturePopupOpen, setIsAddFeaturePopupOpen] = useState(false);
  const [featureList, setFeatureList] = useState<AdminFeature[]>([]);

  useEffect(() => {
    if (features) {
      setFeatureList(features);
    }
  }, [features]);

  const handleSaveNewFeature = async (newFeature: { feature_name: string; created_by: string }) => {
    try {
      await addFeature(newFeature);
      setIsAddFeaturePopupOpen(false); // Close popup after adding
    } catch (error) {
      console.error('Failed to add feature:', error);
    }
  };

  const handleEditFeature = async (updatedFeature: { feature_name: string; updated_by: string }) => {
    if (!editingFeature) return;
    try {
      await editFeature(editingFeature.id, updatedFeature);
      setEditingFeature(null);
    } catch (error) {
      console.error('Failed to edit feature:', error);
    }
  };

  const handleToggleActiveState = async (featureId: number, isActive: boolean) => {
    await toggleFeatureActiveState(featureId, isActive);
  };

  const columns = ['Feature Name', 'Active', 'Created At', 'Updated At', 'Actions'];

  if (loading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <AdminTable<AdminFeature>
        columns={columns}
        data={featureList}
        renderRow={(feature) => (
          <>
            <td>{feature.feature_name}</td>
            <td>{feature.active ? <span className="active">Active</span> : <span className="inactive">Inactive</span>}</td>
            <td>{formatDateTime(feature.created_at)}</td>
            <td>{formatDateTime(feature.updated_at)}</td>
            <td>
              <div className="admin-table-icons">
                <button onClick={() => setEditingFeature(feature)}>
                  <EditIcon className="admin-table-icon" />
                </button>
                <ToggleSwitch
                  isChecked={feature.active}
                  onToggle={() => handleToggleActiveState(feature.id, !feature.active)}
                />
              </div>
            </td>
          </>
        )}
        entityName="Features"
        onAdd={() => setIsAddFeaturePopupOpen(true)}
        showSearchBar={true}
      />

      {editingFeature && (
        <EditFeaturePopup
          feature={editingFeature}
          onClose={() => setEditingFeature(null)}
          onSave={handleEditFeature}
        />
      )}

      {isAddFeaturePopupOpen && (
        <AddFeaturePopup
          onClose={() => setIsAddFeaturePopupOpen(false)}
          onSave={handleSaveNewFeature}
        />
      )}
    </>
  );
};

export default FeaturesTable;
