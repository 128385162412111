import { DataCallLogResponse } from "../types/DataCallLogResponse";
import { client } from "../utils/fetchData";
import { Filters } from "../types/Filters";
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';

const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

export const getCallLogData = (token: string, id: string | undefined, filters: Filters): Promise<DataCallLogResponse> => {
  const params: { [key: string]: any } = {
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
    cities: filters.cities,
    states: filters.states,
    postal_codes: filters.postalCodes,
    campaign: filters.campaign,
    source: filters.source,
    keyword: filters.keyword,
    from_call_count: filters.callRange.minCalls,
    to_call_count: filters.callRange.maxCalls,
  };

  const url = `customer_details?uuid=${id}`;
  return client.post<DataCallLogResponse>(url, token, params);
};

