import { startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear, subDays } from 'date-fns';

export const getCustomStaticRanges = () => {
  const today = new Date();

  return [
    {
      label: 'Today',
      range: () => ({
        startDate: startOfDay(today),
        endDate: endOfDay(today),
      }),
    },
    {
      label: 'Last 7 Days',
      range: () => ({
        startDate: startOfDay(subDays(today, 6)),
        endDate: endOfDay(today),
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: startOfYear(today),
        endDate: endOfYear(today),
      }),
    },
  ];
};

