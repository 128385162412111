import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import closeBtn from '../../assets/images/svg/close-dark.svg';
import emailjs from 'emailjs-com';
import html2canvas from 'html2canvas';
import { EMAIL_JS_CONFIG } from '../../config';
import './FeedbackForm.css';
import { useImageUpload } from '../../hooks/useImageUpload';

interface FeedbackFormProps {
  showScreenshotButton: boolean;
  className: string;
  imageUrl: string;
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ showScreenshotButton, className, imageUrl }) => {
  const [isFormOpen, setFormOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [fromName, setFromName] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', feedback: '' });
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [isLoadingScreenshot, setIsLoadingScreenshot] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { fileUrl, uploading, error: uploadError, upload } = useImageUpload();

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setFromEmail(user.email || 'no-reply@hotanalytics.com');
    }
  }, []);

  const toggleForm = () => {
    setFormOpen(!isFormOpen);
    setSubmissionStatus('');
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromEmail(event.target.value);
  };

  const handleFocus = (field: keyof typeof errors) => {
    setErrors({ ...errors, [field]: '' });
  };

  const validate = () => {
    let isValid = true;
    const newErrors = { name: '', email: '', feedback: '' };

    if (fromName.trim().length < 3) {
      newErrors.name = 'Name must be at least 3 characters long';
      isValid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(fromEmail)) {
      newErrors.email = 'Please enter a valid email address.';
      isValid = false;
    }

    if (feedbackText.trim().length < 10) {
      newErrors.feedback = 'Feedback must be at least 10 characters long.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleScreenshotCapture = async () => {
    setIsLoadingScreenshot(true);

    setTimeout(async () => {
      if (overlayRef.current) {
        overlayRef.current.style.display = 'none';
      }
      try {
        const canvas = await html2canvas(document.body, {
          useCORS: true,
          allowTaint: false,
          x: window.scrollX,
          y: window.scrollY,
          width: window.innerWidth,
          height: window.innerHeight,
        });

        const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
        setScreenshot(dataUrl);
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      } finally {
        setIsLoadingScreenshot(false);
        if (overlayRef.current) {
          overlayRef.current.style.display = 'flex';
        }
      }
    }, 0);
  };



  const handleScreenshotDelete = () => {
    setScreenshot(null);
  };


const handleSubmit = async (event: React.FormEvent) => {
  event.preventDefault();

  if (!validate()) {
    return;
  }

  setSubmissionStatus('uploading');
  try {
    let screenshotUrl = null;

    if (screenshot) {
      try {
        screenshotUrl = await upload(screenshot);
      } catch (err) {
        setSubmissionStatus('error');
        return;
      }
    }

    const templateParams = {
      from_name: fromName || 'Anonymous',
      from_email: fromEmail || 'no-reply@hotanalytics.com',
      feedback: feedbackText,
      screenshot_url: screenshotUrl,
    };

    await emailjs.send(
      EMAIL_JS_CONFIG.SERVICE_ID,
      EMAIL_JS_CONFIG.TEMPLATE_ID,
      templateParams,
      EMAIL_JS_CONFIG.USER_ID
    );

    setSubmissionStatus('success');
    setFeedbackText('');
    setFromName('');
    setScreenshot(null);

  } catch (error) {
    setSubmissionStatus('error');
    console.error('Failed to submit feedback:', error);
  } finally {
    setTimeout(() => {
      setSubmissionStatus('');
    }, 4000);
  }
};

  return (
    <>
      <div className={`fixed-icon ${className}`} onClick={toggleForm}>
        <img src={imageUrl} alt="feedback" className="feedback-call" />
      </div>
      {isFormOpen && (
        <div ref={overlayRef} className='feedback-form-overlay'>
          <div className={classNames('feedback-form-container card-wrapper', { open: isFormOpen })}>
            <button className="close-button" onClick={toggleForm}>
              <img src={closeBtn} alt="close btn" />
            </button>
            <h2>Send Us Feedback</h2>
            <form noValidate onSubmit={handleSubmit}>
              <label>
                Name
                <span>*</span>
                <input
                  type="text"
                  placeholder="Your Name"
                  value={fromName}
                  onChange={handleNameChange}
                  onFocus={() => handleFocus('name')}
                  className={classNames('feedback-form-input', { 'form-input-error': errors.name })}
                />
                {errors.name && <small className="error-text">{errors.name}</small>}
              </label>
              <label>
                Email
                <span>*</span>
                <input
                  type="email"
                  placeholder="Your Email"
                  value={fromEmail}
                  onChange={handleEmailChange}
                  onFocus={() => handleFocus('email')}
                  className={classNames('feedback-form-input', { 'form-input-error': errors.email })}
                />
                {errors.email && <small className="error-text">{errors.email}</small>}
              </label>
              <label>
                Describe Your Feedback
                <span>*</span>
                <textarea
                  placeholder="Tell us what prompted this feedback"
                  value={feedbackText}
                  onChange={handleFeedbackChange}
                  onFocus={() => handleFocus('feedback')}
                  className={classNames('card-wrapper card-wrapper--sm', { 'form-input-error': errors.feedback })}
                />
                {errors.feedback && <small className="error-text">{errors.feedback}</small>}
              </label>
              {uploading ? (
                <div>Loading...</div>
              ) : (
                <>
                  {screenshot ? (
                    <div className="screenshot-preview">
                      <img src={screenshot} alt="screenshot preview" />
                      <button className="delete-screenshot" onClick={handleScreenshotDelete}>
                        <img src={closeBtn} alt="delete screenshot" />
                      </button>
                    </div>
                  ) : (
                    <>
                      {showScreenshotButton && (
                        <button
                          type="button"
                          className="cta cta-secondary"
                          onClick={handleScreenshotCapture}
                          disabled={isLoadingScreenshot}
                        >
                          {isLoadingScreenshot ? 'Capturing Screenshot...' : 'Capture Screenshot'}
                        </button>
                      )}
                      {isLoadingScreenshot && (
                        <div className="feedback-loading-status">
                          Please wait while screenshot is loading
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {uploadError && (
                <div className="error-text">Error uploading image: {uploadError}</div>
              )}
              <button
                className="cta cta-main"
                type="submit"
                disabled={isLoadingScreenshot}
              >
                Send Feedback
              </button>
            </form>
            {submissionStatus === 'success' && (
              <div className="feedback-submission-success">
                Thank you! Your feedback has been submitted.
              </div>
            )}
            {submissionStatus === 'error' && (
              <div className="feedback-submission-error">
                There was an error submitting your feedback. Please try again.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};