import React, { useState } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { AdminRole } from '../../types/AdminRole';
import { useAuth } from '../../contexts/AuthContext';
import { SingleSelectDropdown } from '../SingleSelectDropdown';

interface AddUserPopupProps {
  roles: AdminRole[]; // Pass roles as a prop
  onClose: () => void;
  onSave: (newUser: { email: string; name: string; role_id: number; created_by: string }) => void;
}

export const AddUserPopup: React.FC<AddUserPopupProps> = ({ roles, onClose, onSave }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const validateName = (name: string) => {
    return name.length >= 3;
  };

  const validateEmail = (email: string) => {
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return false;

    const allowedDomains = ['@havenhealthmgmt.org', '@rxunited.com', '@havendetoxnow.com'];
    return allowedDomains.some(domain => email.endsWith(domain));
  };

  const handleSave = async () => {
    setError(null);

    if (!validateName(name)) {
      setError('Name must be at least 3 characters long.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Email must be valid and belong to one of the allowed domains.');
      return;
    }

    setIsLoading(true);
    try {
      const newUser = {
        email,
        name,
        role_id: roleId,
        created_by: user?.email || '',
      };
      console.log('Saving new user:', newUser);
      await onSave(newUser);
      onClose();
    } catch (err) {
      console.error('Failed to add user:', err);
      setError('Failed to add user.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionPopup
      title="Add New User"
      actionType="add"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={isLoading}
      error={error}
    >
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter at least 3 characters"
        />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter a valid email"
        />
      </div>
      <div className="form-group">
        <label>Role:</label>
        <SingleSelectDropdown
          values={roles.map(role => role.role_name)}
          selectedValue={roles.find(role => role.id === roleId)?.role_name || ''}
          onChange={(selectedRoleName) => {
            const selectedRole = roles.find(role => role.role_name === selectedRoleName);
            if (selectedRole) {
              setRoleId(selectedRole.id);
            }
          }}
          name="Role"
        />
      </div>
    </ActionPopup>
  );
};
