import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, User, X } from 'lucide-react';
import { faqData } from '../../utils/faqData';
import './ChatBot.css';
import { findBestMatch } from '../../utils/matchingLogic';
import { Message } from '../../types/Message';

interface ChatBotProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ChatBot({ isOpen, onClose }: ChatBotProps) {
  const [messages, setMessages] = useState<Message[]>([
    {
      type: 'bot',
      content: 'Hello! I am SIFRA, your Hot Analytics assistant. How can I help you today?',
      timestamp: new Date(),
    },
  ]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: Message = {
      type: 'user',
      content: input,
      timestamp: new Date(),
    };

    const bestMatch = findBestMatch(input, faqData);
    const botResponse: Message = {
      type: 'bot',
      content: bestMatch || "I apologize, but I couldn't find a specific answer to your question. Please contact pshah@havenhealthmgmt.org for further assistance.",
      timestamp: new Date(),
    };

    setMessages([...messages, userMessage, botResponse]);
    setInput('');
  };

  if (!isOpen) return null;

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <div className="chatbot-header-title">
          <Bot size={24} />
          <h2>SIFRA - Hot Analytics Assistant</h2>
        </div>
        <button className="chatbot-close-button" onClick={onClose}>
          <X size={20} />
        </button>
      </div>

      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message-container ${
              message.type === 'user' ? 'user-message' : 'bot-message'
            }`}
          >
            <div className="message-avatar">
              {message.type === 'user' ? <User size={16} /> : <Bot size={16} />}
            </div>
            <div className="message-content">
              {message.content}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSend} className="chatbot-input-form">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your question..."
          className="chatbot-input"
        />
        <button type="submit" className="chatbot-send-button">
          <Send size={20} />
        </button>
      </form>
    </div>
  );
}