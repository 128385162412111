import React from 'react';
import searchIcon from '../../assets/images/svg/search.svg';
import filterIcon from '../../assets/images/svg/mage_filter.svg';
import callIdIcon from '../../assets/images/svg/search-param/call-id.svg';
import nameIcon from '../../assets/images/svg/search-param/name.svg';
import phoneIcon from '../../assets/images/svg/search-param/phone.svg';
import './SearchInput.css';

interface SearchInputProps {
  searchText: string;
  setSearchText: (text: string) => void;
  toggleFilter: () => void;
  selectedSearchParam: string;
  setSelectedSearchParam: (param: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ searchText, setSearchText, toggleFilter, selectedSearchParam,
  setSelectedSearchParam, }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (selectedSearchParam === 'phoneNumber' || selectedSearchParam === 'callId') {
      value = value.replace(/\D/g, '');
    }

    setSearchText(value);
  };

  const handleParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchParam(e.target.value);
  };

  return (
    <div className='search-input'>
      <div className="search-input__input">
        <input
          type="search"
          value={searchText}
          onChange={handleInputChange}
          placeholder="Enter name or phone number..."
        />
        <img
          className='search-icon'
          src={searchIcon}
          alt="search"
        />
        <div onClick={toggleFilter} className='filter-btn'>
          <img src={filterIcon} alt="filter-btn-icon" />
        </div>
      </div>
      <div className="search-input__params">
      <input
          type="radio"
          id="callId"
          name="searchParam"
          value="callId"
          checked={selectedSearchParam === 'callId'}
          onChange={handleParamChange}
          hidden
        />
        <label htmlFor="callId">
          <img src={callIdIcon} alt="call id icon" />CallID
        </label>

        <input
          type="radio"
          id="phoneNumber"
          name="searchParam"
          value="phoneNumber"
          checked={selectedSearchParam === 'phoneNumber'}
          onChange={handleParamChange}
          hidden
        />
        <label htmlFor="phoneNumber">
          <img src={phoneIcon} alt="phone icon" />Phone Number
        </label>

        <input
          type="radio"
          id="name"
          name="searchParam"
          value="name"
          checked={selectedSearchParam === 'name'}
          onChange={handleParamChange}
          hidden
        />
        <label htmlFor="name">
          <img src={nameIcon} alt="name icon" />Name
        </label>
      </div>
    </div>
  );
};

export default SearchInput;
