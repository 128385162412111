import { useCallback, useEffect, useState } from "react";
import { AgentMessagesResponse } from "../types/AgentMessagesResponse";
import { useAuth } from "../contexts/AuthContext";
import { getAgentMessages } from "../api/agentMessages";

export const useAgentMessages = (agentId: string | undefined, selectedPhoneNumber: string | null) => {
  const [messages, setMessages] = useState<AgentMessagesResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { userToken } = useAuth();

  const fetchMessages = useCallback(async (pageNumber: number) => {
    if (!userToken || !agentId || !selectedPhoneNumber) {
      setError('Missing token, agentId, or phone number.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const messagesResponse = await getAgentMessages(userToken, agentId, selectedPhoneNumber, pageNumber);
      // console.log(`Newly fetched messages (page ${pageNumber}):`, messagesResponse.messages);

      setMessages((prevMessages: AgentMessagesResponse | null) => {
        if (prevMessages) {
          return {
            ...messagesResponse,
            messages: [...prevMessages.messages, ...messagesResponse.messages],
          };
        }
        return messagesResponse;
      });

      setHasMore(messagesResponse.messages.length === messagesResponse.per_page);
    } catch (err) {
      setError('Failed to fetch messages.');
      console.error('Error fetching messages:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, agentId, selectedPhoneNumber]);

  useEffect(() => {
    setPage(1);
    setMessages(null);
    setHasMore(true);
    fetchMessages(1);
  }, [fetchMessages]);

  return { messages, loading, error, fetchMessages, page, setPage, hasMore };
};

