import { client } from '../utils/fetchData';
import { AdminRole } from '../types/AdminRole';

export const getAdminRolesData = async (token: string | null): Promise<AdminRole[]> => {
  return client.get<AdminRole[]>('roles', token);
};

export const addAdminRoleData = async (token: string, newRole: { role_name: string; is_super_admin: boolean; created_by: string }): Promise<AdminRole> => {
  return client.post<AdminRole>('roles', token, newRole);
};

export const updateAdminRoleData = async (token: string, roleId: number, updatedRole: { role_name: string; is_super_admin: boolean; updated_by: string }): Promise<AdminRole> => {
  return client.put<AdminRole>(`roles/${roleId}`, token, updatedRole);
};
