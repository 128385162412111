import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchActiveDashboards } from '../api/activeDashboards';
import { Dashboard } from '../types/Dashboard';

export const useActiveDashboards = () => {
  const [dashboards, setDashboards] = useState<Dashboard[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchDashboards = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetchActiveDashboards(userToken);
      setDashboards(response);
    } catch (err) {
      setError('Failed to fetch dashboards.');
      console.error('Error fetching dashboards:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);
  
  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  return { dashboards, loading, error };
};
