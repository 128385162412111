import React, { useState } from 'react';
import { Dropdown } from '../Dropdown';
import searchIcon from '../../assets/images/svg/city-search.svg';
import closeIcon from '../../assets/images/svg/clear-icon.svg';
import './MultiSelectDropdown.css';

interface MultiSelectDropdownProps {
  values: string[];
  selectedLabels: string[];
  onChange: (selectedValues: string[]) => void;
  name: string;
}

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ values, selectedLabels, onChange, name }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleOptionClick = (value: string) => {
    const isSelected = selectedLabels.includes(value);
    let updatedSelectedLabels;

    if (isSelected) {
      updatedSelectedLabels = selectedLabels.filter(label => label !== value);
    } else {
      updatedSelectedLabels = [...selectedLabels, value];
    }

    onChange(updatedSelectedLabels);
  };

  const handleRemoveLabel = (value: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const updatedSelectedLabels = selectedLabels.filter(label => label !== value);
    onChange(updatedSelectedLabels);
  };

  const handleLabelClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const filteredValues = values.filter(value =>
    value.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dropdown
      label={`Select ${name}`}
      value={selectedLabels.join(', ')}
      onChange={(value) => handleOptionClick(value)}
      renderHeader={() => (
        <div className="selected-labels">
          {selectedLabels.length > 0 ? (
            selectedLabels.map((label) => (
              <span key={label} className="selected-label" onClick={handleLabelClick}>
                {label}
                <img
                  src={closeIcon}
                  alt="remove"
                  className="remove-icon"
                  onClick={(e) => handleRemoveLabel(label, e)}
                />
              </span>
            ))
          ) : (
            <span>All {name}</span>
          )}
        </div>
      )}
      renderBody={(isOpen, handleOptionClick) => (
        <>
          <div className="dropdown-search-box">
            <input
              type="text"
              placeholder="Search"
              className="dropdown-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              onClick={(e) => e.stopPropagation()}
            />
            <img src={searchIcon} alt="search icon" className="dropdown-search-icon" />
          </div>
          <ul className="dropdown-list">
            {filteredValues.map(value => (
              <li key={value} className="dropdown-list-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedLabels.includes(value)}
                    onChange={() => handleOptionClick(value)}
                  />
                  <span>{value}</span>
                </label>
              </li>
            ))}
          </ul>
        </>
      )}
      className="city-dropdown"
      closeOnSelect={false}
    />
  );
};

