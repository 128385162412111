import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import { useFiltersContext } from '../../contexts/FilterContext';
import { Filters } from '../../types/Filters';
import { DateRangeSelector } from '../DateRangeSelector';
import { MultiSelectDropdown } from '../MultiSelectDropdown';
import './FilterPanel.css';
import { useCitiesData } from '../../hooks/useCitiesData';
import { useStatesData } from '../../hooks/useStatesData';
import { usePostalCodesData } from '../../hooks/usePostalCodesData';
import { useCampaignData } from '../../hooks/useCampaignData';
import { useKeywordsData } from '../../hooks/useKeywordData';
import { useSourceData } from '../../hooks/useSourceData';

interface FilterPanelProps {
  toggleFilter: () => void;
  applyFilters: () => void;
}

export const FilterPanel: React.FC<FilterPanelProps> = ({ toggleFilter, applyFilters }) => {
  const { filters, setFilters, clearFilters } = useFiltersContext();
  const { cities } = useCitiesData();
  const { postalCodes } = usePostalCodesData();
  const { states } = useStatesData();
  const { keywords } = useKeywordsData();
  const { source } = useSourceData();
  const { campaign } = useCampaignData();

  const [localFilters, setLocalFilters] = useState<Filters>(filters);

  const handleCallRangeChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setLocalFilters((prev: Filters) => ({
        ...prev,
        callRange: { minCalls: value[0], maxCalls: value[1] },
      }));
    }
  };

  const handleSelectionChange = (selectedLabels: string[], filterKey: keyof Filters) => {
    setLocalFilters((prev: Filters) => ({
      ...prev,
      [filterKey]: selectedLabels,
    }));
  };

  const handleDateRangeChange = (range: { startDate: Date; endDate: Date; key: string }) => {
    setLocalFilters((prev: Filters) => ({
      ...prev,
      dateRange: range,
    }));
  };

  const handleApply = () => {
    setFilters(localFilters);
    toggleFilter();
    applyFilters();
  };

  const handleClearAllFilters = () => {
    const today = new Date();
    const defaultRange = {
      startDate: today,
      endDate: today,
      key: 'selection',
    };

    setLocalFilters({
      dateRange: defaultRange,
      callRange: { minCalls: 1, maxCalls: 2000 },
      cities: [],
      states: [],
      postalCodes: [],
      campaign: [],
      source: [],
      keyword: [],
    });

    clearFilters();
  };

  return (
    <div className="filter-panel">
      <h2>Filters</h2>
      <div>
        <label>Select dates</label>
        <DateRangeSelector
          initialRange={localFilters.dateRange}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>
      <div className="number-of-calls number-of-calls-slider">
        <label>Number of Calls</label>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          min={1}
          max={2000}
          step={1}
          value={[localFilters.callRange.minCalls, localFilters.callRange.maxCalls]}
          onChange={handleCallRangeChange}
        />
        <div className="call-range-values">
          <p>Min calls: <span>{localFilters.callRange.minCalls}</span></p>
          <p>Max calls: <span>{localFilters.callRange.maxCalls}</span></p>
        </div>
      </div>
      <div>
        <label>Choose Cities</label>
        <MultiSelectDropdown
          values={cities}
          selectedLabels={localFilters.cities}
          onChange={(selectedCities) => handleSelectionChange(selectedCities, 'cities')}
          name='Cities'
        />
      </div>
      <div>
        <label>Choose States</label>
        <MultiSelectDropdown
          values={states}
          selectedLabels={localFilters.states}
          onChange={(selectedStates) => handleSelectionChange(selectedStates, 'states')}
          name='States'
        />
      </div>
      <div>
        <label>Choose Postal Codes</label>
        <MultiSelectDropdown
          values={postalCodes}
          selectedLabels={localFilters.postalCodes}
          onChange={(selectedPostalCodes) => handleSelectionChange(selectedPostalCodes, 'postalCodes')}
          name='Postal Codes'
        />
      </div>
      <div>
        <label>Choose Campaign</label>
        <MultiSelectDropdown
          values={campaign}
          selectedLabels={localFilters.campaign}
          onChange={(selectedCampaignLabels) => handleSelectionChange(selectedCampaignLabels, 'campaign')}
          name='Campaign'
        />
      </div>
      <div>
        <label>Choose Source</label>
        <MultiSelectDropdown
          values={source}
          selectedLabels={localFilters.source}
          onChange={(selectedSourceLabels) => handleSelectionChange(selectedSourceLabels, 'source')}
          name='Source'
        />
      </div>
      <div>
        <label>Choose Keywords</label>
        <MultiSelectDropdown
          values={keywords}
          selectedLabels={localFilters.keyword}
          onChange={(selectedKeywordsLabels) => handleSelectionChange(selectedKeywordsLabels, 'keyword')}
          name='Keyword'
        />
      </div>
      <button type="button" onClick={handleApply} className="cta cta-main">Apply</button>
      <button type="button" onClick={handleClearAllFilters} className="cta cta-secondary">Clear all filters</button>
    </div>
  );
};

