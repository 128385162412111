import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import './Breadcrumbs.css';

const breadcrumbNameMap: { [key: string]: string } = {
  '/customers/:id': 'Customer Detail',
  '/agents/:id': 'Agent Detail',
};

const Breadcrumbs: React.FC = () => {
  const location = useLocation();

  // Check if the current path matches either '/customers/:id' or '/agents/:id'
  const isPatientDetail = matchPath('/customers/:id', location.pathname);
  const isAgentDetail = matchPath('/agents/:id', location.pathname);

  if (!isPatientDetail && !isAgentDetail) {
    // Do not render breadcrumbs for other routes
    return null;
  }

  return (
    <div className="container">
      <nav className="breadcrumbs">
      <ul>
        {isPatientDetail && (
          <>
            <li>
              <Link to="/customers">Customers</Link>
            </li>
            <li>Customer Detail</li>
          </>
        )}
        {isAgentDetail && (
          <>
            <li>
              <Link to="/agents">Agents</Link>
            </li>
            <li>Agent Detail</li>
          </>
        )}
      </ul>
    </nav>
    </div>
    
  );
};

export default Breadcrumbs;
