import React, { useState, useRef, useEffect } from 'react';
import { format, startOfDay, endOfDay } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import './DateRangeSelector.css';
import '../DateRangePickerCustom/DateRangePickerCustom.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import dataRangeCalendar from '../../assets/images/svg/data-range-calendar.svg';
import { getCustomStaticRanges } from '../../utils/dateRanges';
import { useFiltersContext } from '../../contexts/FilterContext';

const toUTCStartOfDay = (date: Date) => toDate(startOfDay(date));
const toUTCEndOfDay = (date: Date) => toDate(endOfDay(date));

const getValidDateRange = (range: { startDate?: Date; endDate?: Date }) => ({
  startDate: toUTCStartOfDay(range.startDate || new Date()),
  endDate: toUTCEndOfDay(range.endDate || new Date()),
  key: 'selection',
});

const customStaticRanges = createStaticRanges(getCustomStaticRanges());

interface DateRangeSelectorProps {
  initialRange: { startDate: Date; endDate: Date; key: string };
  onDateRangeChange: (range: { startDate: Date; endDate: Date; key: string }) => void;
}

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ initialRange, onDateRangeChange }) => {
  const { filters } = useFiltersContext();
  const [showPicker, setShowPicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: toUTCStartOfDay(initialRange.startDate),
      endDate: toUTCEndOfDay(initialRange.endDate),
      key: 'selection',
    },
  ]);

  const pickerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (ranges: any) => {
    const { selection } = ranges;
    const validRange = getValidDateRange(selection);
    setRange([validRange]);
  };

  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const handleClickOutside = (event: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    setRange([
      {
        startDate: toUTCStartOfDay(filters.dateRange.startDate),
        endDate: toUTCEndOfDay(filters.dateRange.endDate),
        key: 'selection',
      },
    ]);
  }, [filters.dateRange]);

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  const handleApplyDates = () => {
    onDateRangeChange({
      startDate: range[0].startDate,
      endDate: range[0].endDate,
      key: 'selection',
    });
    setShowPicker(false);
  };

  const handleClearSelection = () => {
    const today = new Date();
    const defaultRange = getValidDateRange({ startDate: today, endDate: today });
    setRange([defaultRange]);
  };

  return (
    <div className="date-range-selector">
      <input
        type="text"
        readOnly
        value={`${formatDate(range[0].startDate)} — ${formatDate(range[0].endDate)}`}
        onClick={() => setShowPicker(!showPicker)}
      />
      <img src={dataRangeCalendar} alt="calendar icon" onClick={() => setShowPicker(!showPicker)} />
      {showPicker && (
        <div className="picker-container" ref={pickerRef}>
          <DateRangePicker
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            rangeColors={['#F68458']}
            staticRanges={customStaticRanges}
            inputRanges={[]}
          />
          <div className="button-container">
            <button className="cta cta-secondary" onClick={handleClearSelection}>
              Clear selection
            </button>
            <button className="cta cta-main" onClick={handleApplyDates}>
              Set dates
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
