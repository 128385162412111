function similarity(s1: string, s2: string): number {
  const words1 = s1.toLowerCase().split(/\W+/);
  const words2 = s2.toLowerCase().split(/\W+/);
  
  const intersection = words1.filter(word => words2.includes(word));
  const union = new Set([...words1, ...words2]);
  
  return intersection.length / union.size;
}

export function findBestMatch(query: string, faqData: Array<{ question: string; answer: string }>): string | null {
  let bestMatch = null;
  let highestSimilarity = 0.3; // Threshold for minimum similarity

  for (const faq of faqData) {
    const similarityScore = similarity(query, faq.question);
    
    if (similarityScore > highestSimilarity) {
      highestSimilarity = similarityScore;
      bestMatch = faq.answer;
    }
  }

  return bestMatch;
}