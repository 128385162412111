import React from 'react';
import { format } from 'date-fns';
import { Call } from '../../types/Call';
import { FaqQuery } from '../FaqQuery';
import customerInfo from '../../assets/images/svg/categories/customer-information.svg';
import customerRelationship from '../../assets/images/svg/categories/customer-relationship.svg';
import serviceInquiry from '../../assets/images/svg/categories/service-inquiry.svg';
import mentalHealthConcerns from '../../assets/images/svg/categories/mental-health-concerns.svg';
import treatmentDiscussion from '../../assets/images/svg/categories/treatment-discussion.svg';
import customerFeedback from '../../assets/images/svg/categories/customer-feedback.svg';
import operationsAndLogistics from '../../assets/images/svg/categories/operations-and-logistics.svg';
import qualityAssurance from '../../assets/images/svg/categories/quality-assurance.svg';
import marketingAndPromotions from '../../assets/images/svg/categories/marketing-and-promotions.svg';
import miscellaneous from '../../assets/images/svg/categories/miscellaneous.svg';
import source from '../../assets/images/svg/source.svg';
import campaign from '../../assets/images/svg/campaign.svg';
import trackingLabel from '../../assets/images/svg/tracking-label.svg';
import keyword from '../../assets/images/svg/keyword.svg';
import newCaller from '../../assets/images/svg/new-caller.svg';
import callDispo from '../../assets/images/svg/call-dispo.svg';
import dialStatus from '../../assets/images/svg/dial-status.svg';
import inboundCall from '../../assets/images/svg/inbound.svg';
import outboundCall from '../../assets/images/svg/outbound.svg';

import './AgentCallCard.css';
import { CategoryItem } from '../CategoryItem';

interface AgentCallCardProps {
  call: Call;
  onPlay: (audio: HTMLAudioElement) => void;
}

export const AgentCallCard: React.FC<AgentCallCardProps> = ({ call, onPlay }) => {
  const callDate = new Date(call.called_at);
  const formattedDate = format(callDate, "EEE do MMM, yyyy, hh:mm a");

  const handlePlay = (event: React.SyntheticEvent<HTMLAudioElement>) => {
    onPlay(event.currentTarget);
  };

  const categoriesAndIcons: Record<string, string> = {
    'Customer Information': customerInfo,
    'Customer Relationship': customerRelationship,
    'Service Inquiry': serviceInquiry,
    'Mental Health Concerns': mentalHealthConcerns,
    'Treatment Discussion': treatmentDiscussion,
    'Customer Feedback': customerFeedback,
    'Operations and Logistics': operationsAndLogistics,
    'Quality Assurance': qualityAssurance,
    'Marketing and Promotions': marketingAndPromotions,
    'Miscellaneous': miscellaneous,
  };

  const callDirection = call.call_metadata.direction?.toLowerCase();
  const isInbound = callDirection === 'inbound';
  const callIcon = isInbound ? inboundCall : outboundCall;
  const bgColor = isInbound ? 'var(--inbound-bg)' : 'var(--outbound-bg)';

  return (
    <div className="call-card card-wrapper card-wrapper--agent">
      <div className="call-card__metrics">
        {(call.call_metadata.source && call.call_metadata.source !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Source">
              <img src={source} alt="Source" />
            </span>
            <p>{call.call_metadata.source}</p>
          </div>
        )}
        {(call.call_metadata.campaign && call.call_metadata.campaign !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Campaign">
              <img src={campaign} alt="Campaign" />
            </span>
            <p>{call.call_metadata.campaign}</p>
          </div>
        )}
        {(call.call_metadata.tracking_label && call.call_metadata.tracking_label !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Tracking Label">
              <img src={trackingLabel} alt="Tracking Label" />
            </span>
            <p>{call.call_metadata.tracking_label}</p>
          </div>
        )}
        {(call.call_metadata.keyword && call.call_metadata.keyword !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Keyword">
              <img src={keyword} alt="Keyword" />
            </span>
            <p>{call.call_metadata.keyword}</p>
          </div>
        )}
        {(call.call_metadata.is_new_caller) && (
          <div className="call-card__metrics-card">
            <span data-alt="New Caller">
              <img src={newCaller} alt="New Caller" />
            </span>
            <p>New Caller</p>
          </div>
        )}
       {(call.call_metadata.call_dispo && call.call_metadata.call_dispo !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Call Disposition">
              <img src={callDispo} alt="Call Disposition" />
            </span>
            <p>{call.call_metadata.call_dispo}</p>
          </div>
        )}
        {(call.call_metadata.dial_status && call.call_metadata.dial_status !== 'None') && (
          <div className="call-card__metrics-card">
            <span data-alt="Dial Status">
              <img src={dialStatus} alt="Dial Status" />
            </span>
            <p>{call.call_metadata.dial_status}</p>
          </div>
        )}
      </div>

      {/* <hr className="call-card__line" /> */}

      {(!call.transcription && call.audio_url) && (
        <>
          <div className="call-card__audio">
            <audio controls onPlay={handlePlay}>
              <source src={call.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div>Unable to transcribe this audio</div>
        </>
      )}

      {(!call.transcription && !call.audio_url) && (
        <div>No audio call found for this call id</div>
      )}

      {(call.audio_url && call.transcription) && (
        <>
          <div className="call-card__audio">
            <audio controls onPlay={handlePlay}>
              <source src={call.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div className="faq">
            <FaqQuery question="Transcription" answer={call.transcription?.speaker_turns} />
            <FaqQuery question="Analysis" answer={call.analysis} />
            {call.analysis && call.analysis.categories_and_topics_classified && (
              <div className="categories-list">
                {Object.keys(call.analysis.categories_and_topics_classified).map((category) => {
                  const topics = call.analysis?.categories_and_topics_classified?.[category] || [];
                  const iconSrc = categoriesAndIcons[category];

                  if (!iconSrc) {
                    return null;
                  }

                  return (
                    <CategoryItem
                      key={category}
                      category={category}
                      topics={topics}
                      iconSrc={iconSrc}
                      altText={`${category.toLowerCase()} icon`}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
