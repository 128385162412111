
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getCallLogData } from '../api/call';
import { DataCallLogResponse } from '../types/DataCallLogResponse';
import { useFiltersContext } from '../contexts/FilterContext';

export const useCallLogData = (id: string | undefined) => {
  const [callLog, setCallLog] = useState<DataCallLogResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken, isAuthenticated } = useAuth();
  const { filters } = useFiltersContext();

  const fetchCallLogs = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }
    setLoading(true);
    try {
      const response: DataCallLogResponse = await getCallLogData(userToken, id, filters);
      setCallLog(response);
    } catch (err) {
      setError('Failed to fetch call log data.');
      console.log('Error fetching call logs:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, id, filters]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCallLogs();
    }
  }, [id, filters, isAuthenticated, fetchCallLogs]);

  return { callLog, loading, error };
};
