export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatTimeFromMinutes = (totalMinutes: number): string => {
  const days = Math.floor(totalMinutes / 1440); // 1440 minutes in a day
  const hours = Math.floor((totalMinutes % 1440) / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor((totalMinutes % 1) * 60); // Handling any fractional minutes as seconds

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  if (days > 0) {
    return `${days} days, ${formattedTime}`;
  } else {
    return formattedTime;
  }
};



export const parseAndSumTimeStrings = (timeString1: string, timeString2: string): string => {
  const daysPattern = /(\d+) days?/;
  const timePattern = /(\d+):(\d+):(\d+)/;

  const parseTime = (timeString: string) => {
    const daysMatch = timeString.match(daysPattern);
    const timeMatch = timeString.match(timePattern);

    const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    const hours = timeMatch ? parseInt(timeMatch[1], 10) : 0;
    const minutes = timeMatch ? parseInt(timeMatch[2], 10) : 0;
    const seconds = timeMatch ? parseInt(timeMatch[3], 10) : 0;

    return {
      days,
      totalSeconds: seconds + minutes * 60 + hours * 3600
    };
  };

  const time1 = parseTime(timeString1);
  const time2 = parseTime(timeString2);

  let totalDays = time1.days + time2.days;
  let totalSeconds = time1.totalSeconds + time2.totalSeconds;

  let totalHours = Math.floor(totalSeconds / 3600);
  totalSeconds = totalSeconds % 3600;
  let totalMinutes = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds % 60;

  if (totalHours >= 24) {
    totalDays += Math.floor(totalHours / 24);
    totalHours = totalHours % 24;
  }

  return `${totalDays} days, ${totalHours}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
};