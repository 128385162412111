// SingleSelectDropdown.tsx
import React, { useState } from 'react';
import { Dropdown } from '../Dropdown';
import searchIcon from '../../assets/images/svg/city-search.svg';
import './SingleSelectDropdown.css';

interface SingleSelectDropdownProps {
  values: string[];
  selectedValue: string;
  onChange: (selectedValue: string) => void;
  name: string;
}

export const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({ values, selectedValue, onChange, name }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredValues = values.filter(value =>
    value.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dropdown
      label={`Select ${name}`}
      value={selectedValue}
      onChange={(value) => onChange(value)}
      renderHeader={() => (
        <div className="selected-label">
          {selectedValue || `Select ${name}`}
        </div>
      )}
      renderBody={(isOpen, handleOptionClick) => (
        <>
          <div className="dropdown-search-box">
            <input
              type="text"
              placeholder="Search"
              className="dropdown-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              onClick={(e) => e.stopPropagation()}
            />
            <img src={searchIcon} alt="search icon" className="dropdown-search-icon" />
          </div>
          <ul className="dropdown-list">
            {filteredValues.map(value => (
              <li key={value} className="dropdown-list-item" onClick={() => handleOptionClick(value)}>
                {value}
              </li>
            ))}
          </ul>
        </>
      )}
      className="single-select-dropdown"
      closeOnSelect={true}
    />
  );
};
