import { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { updateCustomersData } from '../api/customers';

interface UseUpdateCustomerProps {
  customerId: string;
  customerData: any;
}

export const useUpdateCustomer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const updateCustomer = useCallback(async ({ customerId, customerData }: UseUpdateCustomerProps) => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await updateCustomersData(userToken, customerId, customerData);
    } catch (err) {
      setError('Failed to update customer data.');
      console.error('Error updating customer data:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  return { updateCustomer, loading, error };
};
