import React, { useState } from 'react';
import { DashboardSidebar } from '../../components/DashboardSidebar';
import './AdminPanelPage.css';
import { UsersTable } from '../../components/UsersTable/UsersTable';
import { RolesTable } from '../../components/RolesTable/RolesTable';
import { FeaturesTable } from '../../components/FeaturesTable/FeaturesTable';
import { ReactComponent as UsersIcon } from '../../assets/images/svg/admin-panel/users.svg';
import { ReactComponent as RolesIcon } from '../../assets/images/svg/admin-panel/roles.svg';
import { ReactComponent as FeaturesIcon } from '../../assets/images/svg/admin-panel/features.svg';
import { ReactComponent as RoleFeaturesIcon } from '../../assets/images/svg/admin-panel/role-features.svg';
import RoleFeaturesTable from '../../components/RoleFeaturesTable/RoleFeaturesTable';

export const AdminPanelPage: React.FC = () => {
  const adminMenuItems = [
    {
      label: 'Users',
      icon: <UsersIcon className="menu-icon menu-icon--fill" />,
      content: <div className="sidebar-content-container"><UsersTable /></div>,
    },
    {
      label: 'Roles',
      icon: <RolesIcon className="menu-icon menu-icon--fill" />,
      content: <div className="sidebar-content-container"><RolesTable /></div>,
    },
    {
      label: 'Features',
      icon: <FeaturesIcon className="menu-icon menu-icon--fill" />,
      content: <div className="sidebar-content-container"><FeaturesTable /></div>,
    },
    {
      label: 'Role & Features',
      icon: <RoleFeaturesIcon className="menu-icon menu-icon--fill" />,
      content: <div className="sidebar-content-container"><RoleFeaturesTable /></div>,
    },
  ];

  const [selectedContent, setSelectedContent] = useState<React.ReactNode>(adminMenuItems[0].content);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <div className="sidebar-container">
      <DashboardSidebar
        menuItems={adminMenuItems}
        onMenuItemClick={(content, index) => {
          setSelectedContent(content);
          setActiveIndex(index);
        }}
        activeIndex={activeIndex}
      />
      <div className="content-container">
        {selectedContent}
      </div>
    </div>
  );
};

export default AdminPanelPage;
