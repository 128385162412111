import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getTextMessages } from '../api/textMessages';
import { TextMessagesResponse } from '../types/TextMessagesResponse';

export const useTextMessages = (customerUuid: string | undefined) => {
  const [textMessages, setTextMessages] = useState<TextMessagesResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchTextMessages = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getTextMessages(userToken, customerUuid);
      setTextMessages(response);
    } catch (err) {
      setError('Failed to fetch text messages.');
      console.error('Error fetching text messages:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, customerUuid]);

  useEffect(() => {
    fetchTextMessages();
  }, [fetchTextMessages]);

  return { textMessages, loading, error };
};
