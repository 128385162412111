import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { addAdminFeatureData, getAdminFeaturesData, updateAdminFeatureData, updateFeatureActiveState } from '../api/features';
import { AdminFeature } from '../types/AdminFeature';

export const useAdminFeatures = () => {
  const [features, setFeatures] = useState<AdminFeature[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchFeatures = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getAdminFeaturesData(userToken);
      setFeatures(response);
    } catch (err) {
      setError('Failed to fetch features.');
      console.error('Error fetching features:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  const toggleFeatureActiveState = async (featureId: number, isActive: boolean) => {
    if (!userToken) return;

    try {
      const updatedFeature = await updateFeatureActiveState(userToken, featureId, isActive);
      setFeatures((prevFeatures) =>
        prevFeatures?.map(feature => feature.id === featureId ? updatedFeature : feature) ?? []
      );
    } catch (err) {
      console.error('Error updating feature active state:', err);
    }
  };

  const addFeature = async (newFeatureData: { feature_name: string; created_by: string }) => {
    if (!userToken) return;

    try {
      const newFeature = await addAdminFeatureData(userToken, newFeatureData);
      setFeatures((prevFeatures) => [...(prevFeatures ?? []), newFeature]);
    } catch (err) {
      console.error('Error adding feature:', err);
      throw err;
    }
  };

  const editFeature = async (featureId: number, updatedFeatureData: { feature_name: string; updated_by: string }) => {
    if (!userToken) return;

    try {
      const updatedFeature = await updateAdminFeatureData(userToken, featureId, updatedFeatureData);
      setFeatures((prevFeatures) =>
        prevFeatures?.map(feature => feature.id === featureId ? updatedFeature : feature) ?? []
      );
    } catch (err) {
      console.error('Error editing feature:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchFeatures();
  }, [fetchFeatures]);

  return { features, loading, error, toggleFeatureActiveState, addFeature, editFeature };
};
