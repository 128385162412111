import React, { useState, useRef, useEffect } from 'react';
import { format, setHours, setMinutes } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import TimePicker from 'react-time-picker';
import { useFiltersContext } from '../../contexts/FilterContext';
import '../DateRangeSelector/DateRangeSelector.css';
import '../DateRangePickerCustom/DateRangePickerCustom.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DateTimeRangeSelector.css';
import 'react-time-picker/dist/TimePicker.css';

import dataRangeCalendar from '../../assets/images/svg/data-range-calendar.svg';

export const DateTimeRangeSelector: React.FC = () => {
  const { filters, updateDateRangeAndTab } = useFiltersContext();
  const [showPicker, setShowPicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: filters.dateRange.startDate,
      endDate: filters.dateRange.endDate,
      key: 'selection',
    },
  ]);
  const [startTime, setStartTime] = useState<string | null>('00:00');
  const [endTime, setEndTime] = useState<string | null>('23:59');

  const pickerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (ranges: any) => {
    const { selection } = ranges;
    setRange([selection]);
  };

  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const handleClickOutside = (event: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
      setRange([
        {
          startDate: filters.dateRange.startDate,
          endDate: filters.dateRange.endDate,
          key: 'selection',
        },
      ]);
  
      setStartTime(
        `${String(filters.dateRange.startDate.getHours()).padStart(2, '0')}:${String(
          filters.dateRange.startDate.getMinutes()
        ).padStart(2, '0')}`
      );
      setEndTime(
        `${String(filters.dateRange.endDate.getHours()).padStart(2, '0')}:${String(
          filters.dateRange.endDate.getMinutes()
        ).padStart(2, '0')}`
      );
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    setRange([
      {
        startDate: filters.dateRange.startDate,
        endDate: filters.dateRange.endDate,
        key: 'selection',
      },
    ]);
    setStartTime(
      `${String(filters.dateRange.startDate.getHours()).padStart(2, '0')}:${String(
        filters.dateRange.startDate.getMinutes()
      ).padStart(2, '0')}`
    );
    setEndTime(
      `${String(filters.dateRange.endDate.getHours()).padStart(2, '0')}:${String(
        filters.dateRange.endDate.getMinutes()
      ).padStart(2, '0')}`
    );
  }, [filters.dateRange]);

  const handleTimeChange = () => {
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);

      const updatedStartDate = setMinutes(setHours(range[0].startDate, startHours), startMinutes);
      const updatedEndDate = setMinutes(setHours(range[0].endDate, endHours), endMinutes);

      const updatedRange = {
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        key: 'selection',
      };

      setRange([updatedRange]);
      return updatedRange;
    }
    return null;
  };

  const handleApplyDates = () => {
    const updatedRange = handleTimeChange();
    if (updatedRange) {
      console.log('Applying dates with updated range:', updatedRange);

      updateDateRangeAndTab({
        startDate: updatedRange.startDate,
        endDate: updatedRange.endDate,
        key: 'selection',
      });
    }
    setShowPicker(false);
  };

  const handleClearSelection = () => {
    const today = new Date();
    setRange([
      {
        startDate: today,
        endDate: today,
        key: 'selection',
      },
    ]);
    setStartTime('00:00');
    setEndTime('23:59');
  };

  return (
    <div className="date-range-selector date-range-selector--time">
      <input
        type="text"
        readOnly
        value={`${formatDate(range[0].startDate)} — ${formatDate(range[0].endDate)}`}
        onClick={() => setShowPicker(!showPicker)}
      />
      <img src={dataRangeCalendar} alt="calendar icon" onClick={() => setShowPicker(!showPicker)} />
      {showPicker && (
        <div className="picker-container" ref={pickerRef}>
          <DateRangePicker
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            rangeColors={['#F68458']}
            staticRanges={[]}
            inputRanges={[]}
          />
          <div className="time-selector">
            <div className="time-selector-box">
              <label>Start Time</label>
              <TimePicker
                onChange={setStartTime}
                value={startTime}
                format="HH:mm"
                disableClock
                clearIcon={null}
              />
            </div>
            <div className="time-selector-box">
              <label>End Time</label>
              <TimePicker
                onChange={setEndTime}
                value={endTime}
                format="HH:mm"
                disableClock
                clearIcon={null}
              />
            </div>
          </div>
          <div className="button-container">
            <button className="cta cta-secondary" onClick={handleClearSelection}>
              Clear selection
            </button>
            <button className="cta cta-main" onClick={handleApplyDates}>
              Set dates
            </button>
          </div>
        </div>
      )}
    </div>
  );
};