import React, { useEffect } from 'react';
import './Popup.css';

interface PopupProps {
  message: string;
  onClose: () => void;
}

export const Popup: React.FC<{ message: string; onClose: () => void }> = ({ message, onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};
