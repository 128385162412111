import React, { useState } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { AdminRole } from '../../types/AdminRole';
import { AdminFeature } from '../../types/AdminFeature';
import { SingleSelectDropdown } from '../SingleSelectDropdown';

interface AddRoleFeaturePopupProps {
  roles: AdminRole[];
  features: AdminFeature[];
  onClose: () => void;
  onSave: (roleId: number, featureId: number) => Promise<void>;
}

export const AddRoleFeaturePopup: React.FC<AddRoleFeaturePopupProps> = ({ roles, features, onClose, onSave }) => {
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    if (selectedRoleId === null || selectedFeatureId === null) {
      setError('Both Role and Feature must be selected.');
      return;
    }

    try {
      await onSave(selectedRoleId, selectedFeatureId);
      onClose();
    } catch (err) {
      setError('Failed to add role feature.');
    }
  };

  return (
    <ActionPopup
      title="Add Role Feature"
      actionType="add"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={false}
      error={error}
    >
       <div className="form-group">
        <label>Role</label>
        <SingleSelectDropdown
          name="Role"
          values={roles.map(role => role.role_name)}
          selectedValue={roles.find(role => role.id === selectedRoleId)?.role_name || ''}
          onChange={selectedRoleName => {
            const selectedRole = roles.find(role => role.role_name === selectedRoleName);
            setSelectedRoleId(selectedRole ? selectedRole.id : null);
          }}
        />
      </div>
      <div className="form-group">
        <label>Feature</label>
        <SingleSelectDropdown
          name="Feature"
          values={features.map(feature => feature.feature_name)}
          selectedValue={features.find(feature => feature.id === selectedFeatureId)?.feature_name || ''}
          onChange={selectedFeatureName => {
            const selectedFeature = features.find(feature => feature.feature_name === selectedFeatureName);
            setSelectedFeatureId(selectedFeature ? selectedFeature.id : null);
          }}
        />
      </div>
    </ActionPopup>
  );
};
