import React, { useRef, useCallback, useEffect, useState } from 'react';
import { SingleSelectDropdown } from '../SingleSelectDropdown/SingleSelectDropdown';
import { useAgentPhoneNumbers } from '../../hooks/useAgentPhoneNumbers';
import { useAgentMessages } from '../../hooks/useAgentMessages';
import agentIcon from '../../assets/images/svg/chat-agent.svg';
import customerIcon from '../../assets/images/svg/chat-customer.svg';
import { Loader } from '../Loader';

interface AgentMessagesProps {
  agentId: string;
}

export const AgentMessages: React.FC<AgentMessagesProps> = ({ agentId }) => {
  const { phoneNumbers, selectedPhoneNumber, setSelectedPhoneNumber, loading: phoneNumbersLoading } = useAgentPhoneNumbers(agentId);
  const { messages, loading: messagesLoading, fetchMessages, page, setPage, hasMore } = useAgentMessages(agentId, selectedPhoneNumber);
  
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const handlePhoneNumberChange = (selectedValue: string) => {
    setSelectedPhoneNumber(selectedValue);
    setPage(1);
  };

  const renderMessages = (messages: any[] | undefined) => {
    if (!messages) return null;

    let previousDirection: string | null = null;
    let previousDate: string | null = null;

    return messages.map((message, index) => {
      const messageDate = new Date(message.called_at).toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const messageTime = new Date(message.called_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      const showDate = messageDate !== previousDate;
      const showIcon = message.direction !== previousDirection || showDate;

      previousDirection = message.direction;
      previousDate = messageDate;

      return (
        <div key={index}>
          {showDate && <div className="message-date">{messageDate}</div>}
          <div className={`message-container ${message.direction}`}>
            {showIcon && (
              <div
                className={`chat-message__icon ${message.direction === 'msg_inbound' ? 'inbound-icon' : 'outbound-icon'}`}
                data-agent-name={message.agent_name} data-customer-name={message.customer_name}
              >
                <img src={message.direction === 'msg_inbound' ? customerIcon : agentIcon} alt="icon" className="chat-icon" />
              </div>
            )}
            <div className={`chat-message ${message.direction === 'msg_inbound' ? 'inbound' : 'outbound'}`}>
              <p>{message.message_body}</p>
              <p className="chat-message__featured-text">
                <span>{`#${message.call_id}`}</span>
                <span>{messageTime}</span>
              </p>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleScroll = useCallback(() => {
    if (!containerRef.current || messagesLoading || isFetchingNextPage || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      setIsFetchingNextPage(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [messagesLoading, hasMore, isFetchingNextPage, setPage]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (page > 1 || selectedPhoneNumber) {
      const container = containerRef.current;
      const previousScrollHeight = container?.scrollHeight || 0;

      fetchMessages(page).then(() => {
        setIsFetchingNextPage(false);

        if (container) {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop += (newScrollHeight - previousScrollHeight);
        }
      });
    }
  }, [page, fetchMessages, selectedPhoneNumber]);

  if (phoneNumbersLoading) {
    return <Loader />;
  }

  return (
    <div className="message-chat-board">
      <div className="messages-dropdown">
        <SingleSelectDropdown
          values={phoneNumbers}
          selectedValue={selectedPhoneNumber || ''}
          onChange={handlePhoneNumberChange}
          name="Phone Number"
        />
      </div>
      {messages && messages.messages.length > 0 ? (
        <div className="card-wrapper card-wrapper--sm">
          <div ref={containerRef} className="messages-container">
            {renderMessages(messages.messages)}
            {isFetchingNextPage && hasMore && <div className="loading-next-page">Loading more messages...</div>}
          </div>
        </div>
      ) : (
        <p>No messages available</p>
      )}
    </div>
  );
};

export default AgentMessages;
