import React, { useEffect, useState } from 'react';
import { DashboardSidebar } from '../../components/DashboardSidebar';
import Iframe from "@nicholasadamou/react-iframe";
import './DashboardsPage.css';
import { useActiveDashboards } from '../../hooks/useActiveDashboards';
import { ReactComponent as Chart1 } from '../../assets/images/svg/charts/chart-icon1.svg';
import { ReactComponent as Chart2 } from '../../assets/images/svg/charts/chart-icon2.svg';
import { ReactComponent as Chart3 } from '../../assets/images/svg/charts/chart-icon3.svg';
import { ReactComponent as Chart4 } from '../../assets/images/svg/charts/chart-icon4.svg';
import { ReactComponent as Chart5 } from '../../assets/images/svg/charts/chart-icon5.svg';
import { ReactComponent as Chart6 } from '../../assets/images/svg/charts/chart-icon6.svg';
import { ReactComponent as Chart7 } from '../../assets/images/svg/charts/chart-icon7.svg';
import { ReactComponent as Chart8 } from '../../assets/images/svg/charts/chart-icon8.svg';
import { ReactComponent as Chart9 } from '../../assets/images/svg/charts/chart-icon9.svg';
import { ReactComponent as Chart10 } from '../../assets/images/svg/charts/chart-icon10.svg';

export const DashboardsPage: React.FC = () => {
  const { dashboards, loading, error } = useActiveDashboards();

  // Initialize state variables (hooks must be called unconditionally)
  const icons = [
    <Chart1 className="menu-icon menu-icon--fill" />,
    <Chart2 className="menu-icon menu-icon--fill" />,
    <Chart3 className="menu-icon menu-icon--fill" />,
    <Chart4 className="menu-icon menu-icon--fill" />,
    <Chart5 className="menu-icon menu-icon--stroke" />,
    <Chart6 className="menu-icon menu-icon--fill" />,
    <Chart7 className="menu-icon menu-icon--fill" />,
    <Chart8 className="menu-icon menu-icon--fill" />,
    <Chart9 className="menu-icon menu-icon--fill" />,
    <Chart10 className="menu-icon menu-icon--fill" />,
  ];

  const getUrlWithToken = (baseUrl: string, token: string) => {
    const url = new URL(baseUrl);
    url.searchParams.append('auth_token', token);
    url.searchParams.append('kiosk', '1');
    url.searchParams.append('fullscreen', 'true');
    url.searchParams.append('theme', 'light');
    return url.toString();
  };

  // Set default values for menuItems, selectedContent, and activeIndex
  const menuItems = dashboards
    ? dashboards.map((dashboard, index) => ({
        label: dashboard.dashboard_name,
        icon: icons[index % icons.length],
        content: (
          <Iframe
            src={getUrlWithToken(dashboard.dashboard_url, dashboard.token)}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        ),
      }))
    : [];

  const [selectedContent, setSelectedContent] = useState<React.ReactNode>(menuItems[0]?.content || <div>Please select a dashboard</div>);
  const [activeIndex, setActiveIndex] = useState<number>(0); // Default to the first menu item

  useEffect(() => {
    if (menuItems.length > 0) {
      setSelectedContent(menuItems[0].content);
      setActiveIndex(0);
    }
  }, [dashboards]);

  // Handle loading, error, or no dashboards states after hooks are initialized
  if (loading) {
    return <div>Loading dashboards...</div>;
  }

  if (error) {
    return <div>Error loading dashboards: {error}</div>;
  }

  if (!dashboards || dashboards.length === 0) {
    return <div>No dashboards available</div>;
  }

  return (
    <div className="sidebar-container">
      <DashboardSidebar
        menuItems={menuItems}
        onMenuItemClick={(content, index) => {
          setSelectedContent(content);
          setActiveIndex(index); // Update active index when a menu item is clicked
        }}
        activeIndex={activeIndex} // Pass the active index to make the first one active
      />
      <div className="iframe-container">
        {selectedContent}
      </div>
    </div>
  );
};

export default DashboardsPage;
