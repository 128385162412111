import React, { useRef, useState, useEffect, ReactNode } from 'react';
import './Slider.css';

interface SliderProps {
  children: ReactNode[];
}

const Slider: React.FC<SliderProps> = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (containerRef.current?.offsetLeft || 0));
    setScrollLeft(containerRef.current?.scrollLeft || 0);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (containerRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 2; // The multiplier can adjust the drag speed
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleChildClick = (index: number) => {
    const child = containerRef.current?.children[index] as HTMLElement;
    if (child && containerRef.current) {
      const childLeft = child.offsetLeft;
      const childRight = childLeft + child.offsetWidth;
      const containerLeft = containerRef.current.scrollLeft;
      const containerRight = containerLeft + containerRef.current.offsetWidth;

      if (childLeft < containerLeft) {
        containerRef.current.scrollTo({ left: childLeft, behavior: 'smooth' });
      } else if (childRight > containerRight) {
        containerRef.current.scrollTo({ left: childRight - containerRef.current.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="slider-container"
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {React.Children.map(children, (child, index) => (
        <div onClick={() => handleChildClick(index)} className="slider-item">
          {child}
        </div>
      ))}
    </div>
  );
};

export default Slider;
