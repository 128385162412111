import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getRoleFeatureLinkageData, updateRoleFeatureAccess } from '../api/roleFeatures';
import { AdminRoleFeature } from '../types/AdminRoleFeature';

export const useAdminRoleFeatures = () => {
  const { userToken, user } = useAuth();
  const [roleFeatures, setRoleFeatures] = useState<AdminRoleFeature[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchRoleFeatures = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getRoleFeatureLinkageData(userToken);
      setRoleFeatures(response ?? []);
    } catch (err) {
      setError('Failed to fetch role features.');
      console.error('Error fetching role features:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  const addOrUpdateRoleFeature = async (
    roleId: number,
    featureId: number,
    canAccess: boolean
  ): Promise<AdminRoleFeature> => {
    if (!userToken) throw new Error('User token is not available.');

    try {
      const roleFeatureData = {
        feature_id: featureId,
        can_access: canAccess,
        updated_by: user?.email || '',
      };

      const response = await updateRoleFeatureAccess(userToken, roleId, roleFeatureData);
      return response as AdminRoleFeature;
    } catch (err) {
      console.error('Error in addOrUpdateRoleFeature:', err);
      throw err;
    }
  };

  const toggleRoleFeatureAccess = async (roleId: number, featureId: number, canAccess: boolean) => {
    try {
      await addOrUpdateRoleFeature(roleId, featureId, !canAccess);
      setRoleFeatures((prevRoleFeatures) =>
        prevRoleFeatures?.map((rf) =>
          rf.role_id === roleId && rf.feature_id === featureId
            ? { ...rf, can_access: !canAccess }
            : rf
        ) ?? []
      );
    } catch (err) {
      console.error('Error toggling role feature access:', err);
    }
  };

  useEffect(() => {
    fetchRoleFeatures();
  }, [fetchRoleFeatures]);

  return { roleFeatures, addOrUpdateRoleFeature, toggleRoleFeatureAccess, loading, error };
};
