import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { CustomerProvider } from './contexts/CustomerContext';
import { useAuth } from './contexts/AuthContext';
import CustomerSearchPage from './pages/CustomerSearchPage/CustomerSearchPage';
import CustomerDetailPage from './pages/CustomerDetailPage/CustomerDetailPage';
import { AgentsSearchPage } from './pages/AgentsSearchPage/AgentsSearchPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import DashboardsPage from './pages/DashboardsPage/DashboardsPage';
import AskPage from './pages/AskPage/AskPage';
import { AgentProvider } from './contexts/AgentContext';
import { FilterProvider } from './contexts/FilterContext';
import AgentDetailPage from './pages/AgentDetailPage/AgentDetailPage';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import NotAuthorizedPage from './pages/NotAuthorizedPage/NotAuthorizedPage';
import { AdminPanelPage } from './pages/AdminPanelPage/AdminPanelPage';
import Joyride, { Step } from 'react-joyride';

const PrivateRoute: React.FC<{ children: JSX.Element; requiredFeature: string }> = ({ children, requiredFeature }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (user?.features && !user.features.includes(requiredFeature)) {
    return <Navigate to="/not-authorized" replace />;
  }

  return children;
};


export const App = () => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();
  const [runTour, setRunTour] = useState(false);
  const navigate = useNavigate();

  const steps: Step[] = [
    {
      target: '.nav-customer',
      content: 'Manage, apply filters and search for customers, view detailed profiles.',
    },
    {
      target: '.nav-agent',
      content: 'Manage, apply filters and search for agents, view detailed profiles.',
    },
    {
      target: '.nav-ask',
      content: 'Use Ask AI for insights and assistance.',
    },
    {
      target: '.nav-dashboard',
      content: 'Monitor overall performance using the dashboard.',
    },
    {
      target: '.header-feedback-icon',
      content: 'Share your feedback or report any issues',
    },
    {
      target: '.chatbot-toggle',
      content: 'Interact with our chatbot for quick assistance.',
    },

    {
      target: '.search-input__input .filter-btn',
      content: 'Apply advanced filters to find customers quickly.',
    },
    {
      target: '.overall-filters__range',
      content: 'Choose a date range to filter specific data',
    },
    {
      target: '.patient-card',
      content: 'Review short info about customer. Click to see more details about calls, messages and more.',
    },
    {
      target: '.search-bar.agent-search-bar .filter-btn',
      content: 'Apply advanced filters to find agents quickly.',
    },

    {
      target: '.overall-filters__range',
      content: 'Choose a date range to filter specific data',
    },
    {
      target: '.overall-stats__item',
      content: 'Get an overview of call-related data.',
    },
    {
      target: '.agent-card__content-info',
      content: 'Review detailed call timing analysis for agents.',
    },
    {
      target: '.agent-card__name img',
      content: 'Access more information by viewing an agent\'s card.',
    }
  ];

  useEffect(() => {
    if (isAuthenticated && user?.isNew) {
      setRunTour(true);
    }
  }, [isAuthenticated, user]);

  return (
    <div className="App">
      {location.pathname !== '/login' && <Header />}
      {location.pathname !== '/login' && <Breadcrumbs />}

      <Joyride
        steps={steps}
        continuous
        showSkipButton
        run={runTour}
        callback={(data) => {
          const { action, index, status } = data;

          const step = steps[index];

          if (step.target === '.search-bar.agent-search-bar .filter-btn' && location.pathname !== '/agents') {
            navigate('/agents', { replace: true });
            return;
          }

          if (['finished', 'skipped'].includes(status)) {
            setRunTour(false);
          }
        }}
        disableScrolling={true}
        styles={{
          options: {
            backgroundColor: '#ffffff',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#F68458',
            textColor: '#26383B',
            zIndex: 1000,
          },
          buttonNext: {
            backgroundColor: '#F68458',
          },
          buttonBack: {
            color: '#F68458',
          },
        }}
      />
      <main>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Navigate to="/customers" replace />} />

          <Route path="/customers/*" element={
            <PrivateRoute requiredFeature="customers">
              <FilterProvider>
                <CustomerProvider>
                  <CustomerSearchPage />
                </CustomerProvider>
              </FilterProvider>
            </PrivateRoute>
          } />
          <Route path="/customers/:id" element={
            <PrivateRoute requiredFeature="customers">
              <FilterProvider>
                <CustomerProvider>
                  <CustomerDetailPage />
                </CustomerProvider>
              </FilterProvider>
            </PrivateRoute>
          } />
          <Route path="/agents/*" element={
            <PrivateRoute requiredFeature="agents">
              <FilterProvider>
                <AgentProvider>
                  <AgentsSearchPage />
                </AgentProvider>
              </FilterProvider>
            </PrivateRoute>
          } />

          <Route path="/agents/:id" element={
            <PrivateRoute requiredFeature="agents">
              <FilterProvider>
                <AgentProvider>
                  <AgentDetailPage />
                </AgentProvider>
              </FilterProvider>
            </PrivateRoute>
          } />

          <Route path="/dashboards/*" element={
            <PrivateRoute requiredFeature="dashboard">
              <DashboardsPage />
            </PrivateRoute>
          } />

          <Route path="/ask" element={
            <PrivateRoute requiredFeature="ask-ai">
              <AskPage />
            </PrivateRoute>
          } />

          <Route path="/admin-panel" element={
            <PrivateRoute requiredFeature="admin-panel">
              <AdminPanelPage />
            </PrivateRoute>
          } />

          <Route path="/not-authorized" element={<NotAuthorizedPage />} />
        </Routes>
      </main>
    </div>
  );
};


export default App;

