import React from 'react';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../../assets/images/svg/google-logo.svg';
import { BASE_URL,ENV } from '../../config';

export const GoogleLoginButton: React.FC = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = `${BASE_URL}login?env=${ENV}`;
  };

  return (
    <button className='cta cta-secondary google-btn' onClick={handleLogin}>
      <img src={googleLogo} alt="google logo" />
      Login with Google
    </button>
  );
};
