// import { useState, useCallback } from 'react';
// import { uploadImage } from '../api/uploadImage';
// import { useAuth } from '../contexts/AuthContext';

// export const useImageUpload = () => {
//   const [fileUrl, setFileUrl] = useState<string | null>(null);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const { userToken } = useAuth();

//   const upload = useCallback(
//     async (imageBase64: string) => {
//       if (!userToken) {
//         setError('User token is not available.');
//         return;
//       }

//       setUploading(true);
//       setError(null);

//       try {
//         const response = await uploadImage(userToken, imageBase64);
//         setFileUrl(response.file_url); // Assuming the response contains a field `file_url`
//       } catch (err) {
//         setError('Failed to upload image.');
//         console.error('Error uploading image:', err);
//       } finally {
//         setUploading(false);
//       }
//     },
//     [userToken]
//   );

//   return { fileUrl, uploading, error, upload };
// };


import { useState, useCallback } from 'react';
import { uploadImage } from '../api/uploadImage';
import { useAuth } from '../contexts/AuthContext';

export const useImageUpload = () => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const upload = useCallback(
    async (imageBase64: string) => {
      setUploading(true);
      setError(null);

      try {
        if (!userToken) throw new Error('User token is not available.');

        const response = await uploadImage(userToken, imageBase64);
        setFileUrl(response.file_url);
        return response.file_url;
      } catch (err) {
        setError('Failed to upload image.');
        console.error('Error uploading image:', err);
        throw err; 
      } finally {
        setUploading(false);
      }
    },
    [userToken]
  );

  return { fileUrl, uploading, error, upload };
};
