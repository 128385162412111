import { client } from '../utils/fetchData';
import { AdminFeature } from '../types/AdminFeature';

export const getAdminFeaturesData = async (token: string | null): Promise<AdminFeature[]> => {
  return client.get<AdminFeature[]>('features', token);
};

export const updateAdminFeatureData = async (token: string | null, featureId: number, updatedFeature: { feature_name: string; updated_by: string }): Promise<AdminFeature> => {
  return client.put<AdminFeature>(`features/${featureId}`, token, updatedFeature);
};

export const updateFeatureActiveState = async (token: string | null, featureId: number, isActive: boolean): Promise<AdminFeature> => {
  return client.put<AdminFeature>(`features/${featureId}/active`, token, { is_active: isActive });
};

export const addAdminFeatureData = async (token: string | null, newFeature: { feature_name: string; created_by: string }): Promise<AdminFeature> => {
  return client.post<AdminFeature>('features', token, newFeature);
};
