import React, { useState } from 'react';
import classnames from 'classnames';
import './FaqQuery.css';
import { SpeakerTurn } from '../../types/SpeakerTurn';
import { EntityRecognized } from '../../types/EntityRecognized';
import { Analysis } from '../../types/Analysis';
import { CallMetadata } from '../../types/CallMetadata';
import { TextMessagesResponse } from '../../types/TextMessagesResponse';
import MessageChatBoard from '../MessageChatBoard/MessageChatBoard';

interface FaqQueryProps {
  question: string;
  answer: string | EntityRecognized | SpeakerTurn[] | Analysis | CallMetadata | TextMessagesResponse | undefined | null;
}

export const FaqQuery: React.FC<FaqQueryProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const formatKey = (key: string) => {
    const formattedKey = key.replace(/_/g, ' ');
    if (key === 'ga_cid') {
      return 'GA CID';
    }
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  };

  const isTextMessagesResponse = (value: any): value is TextMessagesResponse => {
    return value && typeof value === 'object' && Object.keys(value).every(key => Array.isArray(value[key]));
  };

  const renderEntityRecognized = (entity: EntityRecognized) => {
    const nonEmptyEntries = Object.entries(entity).filter(([, value]) => value.length > 0);
    if (nonEmptyEntries.length === 0) {
      return <p>No Entities Recognized found</p>;
    }
    return (
      <div className="entity-recognized">
        {nonEmptyEntries.map(([key, value], index) => (
          <p key={index}>
            <span className="featured-text">{formatKey(key)}: </span>
            <span>{Array.isArray(value) ? value.join(', ') : value}</span>
          </p>
        ))}
      </div>
    );
  };

  const renderAnalysis = (analysis: Analysis) => {
    const { call_note, concerns, follow_up_text_message, improvisation_needs } = analysis;

    return (
      <>
      <hr className="faq__line" />
      <div className="analysis">
        {call_note && (
          <p>
            <span className="featured-text">Call Note: </span>
            <span>{call_note}</span>
          </p>
        )}
        {concerns && (
          <p>
            <span className="featured-text">Concerns: </span>
            <span>{Array.isArray(concerns) ? concerns.join(', ') : concerns}</span>
          </p>
        )}
        {follow_up_text_message && (
          <p>
            <span className="featured-text">Follow-up Text Message: </span>
            <span>{follow_up_text_message}</span>
          </p>
        )}
        {improvisation_needs && (
          <p>
            <span className="featured-text">Improvisation Needs: </span>
            <span>{improvisation_needs}</span>
          </p>
        )}
        {(!call_note && (!concerns || (Array.isArray(concerns) && concerns.length === 0)) && !follow_up_text_message && !improvisation_needs) && (
          <p>No Analysis Data found</p>
        )}
      </div>
      </>
    );
  };

  const renderCallMetadata = (metadata: CallMetadata) => {
    const timingDetailsKeys = [
      'duration',
      'talk_time',
      'ring_time',
      'hold_time',
      'wait_time',
      'dial_status',
    ];
  
    const crmIntegrationKeys = [
      'salesforce_reference',
      'salesforce_url',
      'ga_cid',
      'notes',
      'call_disposition',
    ];
  
    const filteredEntries = Object.entries(metadata).filter(
      ([key, value]) =>
        value !== 'None' &&
        value !== 'N/A' &&
        typeof value !== 'boolean' &&
        value !== null &&
        value !== undefined &&
        value !== 0 &&
        !['source', 'campaign', 'tracking_label', 'keyword'].includes(key)
    );
  
    const timingDetailsEntries = filteredEntries.filter(([key]) =>
      timingDetailsKeys.includes(key)
    );
  
    const crmIntegrationEntries = filteredEntries.filter(([key]) =>
      crmIntegrationKeys.includes(key)
    );
  
    if (filteredEntries.length === 0) {
      return <p>No valid Call Metadata found</p>;
    }
  
    return (
      <div className="call-metadata">
        <div className="metadata-grid">
          <div className="call-timing-details">
            <h3>Call Timing Details</h3>
            {timingDetailsEntries.map(([key, value], index) => (
              <div key={index} className="metadata-grid-item">
                <span className="featured-text">{formatKey(key)}: </span>
                {key === 'wait_time' && typeof value === 'number' ? (
                  <span>{`${value} ms`}</span>
                ) : typeof value === 'number' ? (
                  <span>{`${value} s`}</span>
                ) : (
                  <span>{value.toString()}</span>
                )}
              </div>
            ))}
          </div>
          <div className="crm-integration">
            <h3>CRM Integration</h3>
            {crmIntegrationEntries.map(([key, value], index) => (
              <div key={index} className="metadata-grid-item">
                <span className="featured-text">{formatKey(key)}: </span>
                {key === 'salesforce_url' ? (
                  <a
                    href={value.toString()}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    Open external link
                  </a>
                ) : (
                  <span>{value.toString()}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    // if (Array.isArray(answer)) {
    //   return (
    //     <div className="grid-container">
    //       <div className="grid-header">Speaker Name</div>
    //       <div className="grid-header">Role</div>
    //       <div className="grid-header">Transcription</div>
    //       {answer.map((item, index) => (
    //         <React.Fragment key={index}>
    //           <div className="grid-cell">{item.speaker_name}</div>
    //           <div className="grid-cell">{item.role}</div>
    //           <div className="grid-cell">{item.transcription}</div>
    //         </React.Fragment>
    //       ))}
    //     </div>
    //   );
    // }
    if (Array.isArray(answer)) {
      return (
        <>
         <hr className="faq__line" />
         <div className="conversation-container">
          <div className="conversation-role">
            <p className="conversation-role--agent"><span></span> Agent</p>
            <p className="conversation-role--customer"><span></span> Customer</p>
          </div>
          <div className='conversation-list'>
          {answer.map((item, index) => (
            <div
              key={index}
              className={`conversation-item ${
                item.role === 'Customer' ? 'customer' : 'agent'
              }`}
            >
              <span className="conversation-item__bubble"></span>
              <p className="conversation-item__message">
                {item.transcription}
              </p>
            </div>
          ))}
          </div>
        </div>
        </>
      );
    }

    if (typeof answer === 'object' && answer !== null) {
      if ('medications' in answer) {
        return renderEntityRecognized(answer as EntityRecognized);
      }

      if ('call_note' in answer) {
        return renderAnalysis(answer as Analysis);
      }

      if ('source' in answer) {
        return renderCallMetadata(answer as CallMetadata);
      }

      if (isTextMessagesResponse(answer)) {
        const hasMessages = answer && Object.keys(answer).length > 0 && Object.values(answer).some(messages => messages.length > 0);
        if (hasMessages) {
          return <MessageChatBoard textMessages={answer} />;
        } else {
          return <p>No text messages available</p>;
        }
      }
    }

    if (typeof answer === 'string') {
      return <p>{answer}</p>;
    }

    return <p>Not available</p>;
  };

  return (
    <div className={classnames('faq-query', { open: isOpen })}>
      <span className="faq-query__question" onClick={toggleAccordion}>
        {question}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12.5 3L12.5 21" stroke="#26383B" strokeWidth="2" strokeLinecap="round" />
          <path d="M21.5 12L3.5 12" stroke="#26383B" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </span>
      <div className="faq-query__answer">
        {renderAnswer()}
      </div>
    </div>
  );
};

export default FaqQuery;
