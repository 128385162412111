import { useState, useEffect} from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getStatesData } from '../api/states';
import { DataStatesResponse } from '../types/DataStatesResponse';

export const useStatesData = () => {
  const [states, setStates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();

  const fetchStates = async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataStatesResponse = await getStatesData(userToken);
      setStates(response.states);
    } catch (err) {
      console.log('Error fetching cities:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchStates();
    }
  }, []);
  return { states, loading };
};