import React, { useState, useEffect } from 'react'; 
import { AdminTable } from '../AdminTable';
import { AdminUser } from '../../types/AdminUser';
import { formatDateTime } from '../../utils/formatDateTime';
import { EditUserPopup } from '../EditUserPopup/EditUserPopup';
import { AddUserPopup } from '../AddUserPopup/AddUserPopup';
import { ReactComponent as EditIcon } from '../../assets/images/svg/edit-user1.svg';
import { ToggleSwitch } from '../ToggleSwitch';
import { useAdminUsers } from '../../hooks/useAdminUsers';
import { useAdminRoles } from '../../hooks/useAdminRoles';
import { Loader } from '../Loader';

export const UsersTable: React.FC = () => {
  const { users, toggleUserActiveState, addUser, updateUser, loading, error } = useAdminUsers();
  const { roles, loading: rolesLoading, error: rolesError } = useAdminRoles();
  const [editingUser, setEditingUser] = useState<AdminUser | null>(null);
  const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
  const [userList, setUserList] = useState<AdminUser[]>([]);

  useEffect(() => {
    if (users) {
      setUserList(users);
    }
  }, [users]);

  const handleToggleActiveState = async (userId: number, isActive: boolean) => {
    await toggleUserActiveState(userId, isActive);
  };

  const handleSaveNewUser = async (newUser: { email: string; name: string; role_id: number; created_by: string }) => {
    await addUser(newUser);
    setIsAddUserPopupOpen(false);
  };

  const handleSaveUser = async (updatedUserData: { name: string; role_id: number; updated_by: string }) => {
    if (!editingUser) return;

    await updateUser(editingUser.id, updatedUserData);

    setUserList(userList.map(user =>
      user.id === editingUser.id
        ? { ...user, ...updatedUserData }
        : user
    ));
    setEditingUser(null);
  };

  const columns = ['Email', 'Name', 'Role', 'Active', 'Created At', 'Updated At', 'Last Logged In', 'Actions'];

  if (loading || rolesLoading) return <Loader />;
  if (error || rolesError) return <div>{error || rolesError}</div>;

  return (
    <>
      <AdminTable<AdminUser>
        columns={columns}
        data={userList}
        renderRow={(user) => (
          <>
            <td>{user.email}</td>
            <td>{user.name}</td>
            <td>{roles.find(role => role.id === user.role_id)?.role_name}</td>
            <td>{user.active ? <span className="active">Active</span> : <span className="inactive">Inactive</span>}</td>
            <td>{formatDateTime(user.created_at)}</td>
            <td>{formatDateTime(user.updated_at)}</td>
            <td>{user.last_logged_in ? formatDateTime(user.last_logged_in) : 'Never'}</td>
            <td>
              <div className="admin-table-icons">
                <button onClick={() => setEditingUser(user)}>
                  <EditIcon className="admin-table-icon" />
                </button>
                <ToggleSwitch
                  isChecked={user.active}
                  onToggle={() => handleToggleActiveState(user.id, !user.active)}
                />
              </div>
            </td>
          </>
        )}
        entityName="Users"
        onAdd={() => setIsAddUserPopupOpen(true)}
        showSearchBar={true}
      />

      {editingUser && (
        <EditUserPopup
          adminUser={editingUser}
          roles={roles}
          onClose={() => setEditingUser(null)}
          onSave={handleSaveUser}
        />
      )}

      {isAddUserPopupOpen && (
        <AddUserPopup
          roles={roles}
          onClose={() => setIsAddUserPopupOpen(false)}
          onSave={handleSaveNewUser}
        />
      )}
    </>
  );
};

