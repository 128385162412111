import { client } from '../utils/fetchData';
import { DataAgentsResponse } from '../types/DataAgentsResponse';

export const postAgentsData = async (token: string | null, data: any): Promise<DataAgentsResponse> => {
  return client.post<DataAgentsResponse>('agent', token, data);
};

export const updateAgentsData = async (token: string | null, agentId: string, data: any): Promise<void> => {
  const url = `agents?agent_id=${agentId}`;
  await client.put<void>(url, token, data);
};
