import { client } from '../utils/fetchData';
import { AgentMessagesResponse } from '../types/AgentMessagesResponse';

export const getAgentMessages = async (token: string | null, agentId: string, agentPhoneNumber: string, page: number = 1): Promise<AgentMessagesResponse> => {
  const url = `agent_text_messages?agent_phone_number=${encodeURIComponent(agentPhoneNumber)}&agent_id=${agentId}&page=${page}`;
  return client.get<AgentMessagesResponse>(url, token);
};

export const getAgentPhoneNumbers = async (token: string | null, agentId: string): Promise<string[]> => {
  const url = `agent_phone_numbers?agent_id=${agentId}`;
  return client.get<string[]>(url, token);
};
