import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Filters } from '../types/Filters';
import { getCustomStaticRanges } from '../utils/dateRanges';
import { isSameDay } from 'date-fns';

interface FilterContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  activeTab: string | null;
  setActiveTab: (tab: string | null) => void;
  clearFilters: () => void;
  updateDateRangeAndTab: (newDateRange: Filters['dateRange']) => void; 
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const getDefaultFilters = (): Filters => {
  const today = new Date();

  const startOfDay = new Date(today.setHours(0, 0, 0, 0));
  const endOfDay = new Date(today.setHours(23, 59, 59, 999));

  return {
    dateRange: {
      startDate: startOfDay,
      endDate: endOfDay,
      key: 'selection',
    },
    callRange: { minCalls: 1, maxCalls: 2000 },
    cities: [],
    states: [],
    postalCodes: [],
    campaign: [],
    source: [],
    keyword: [],
  };
};

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>(getDefaultFilters());
  const [activeTab, setActiveTab] = useState<string | null>('Today');

  const customStaticRanges = getCustomStaticRanges();

  const updateDateRangeAndTab = (newDateRange: Filters['dateRange']) => {
    const matchingRange = customStaticRanges.find(({ range }) => {
      const { startDate, endDate } = range();
      return (
        isSameDay(startDate, newDateRange.startDate) &&
        isSameDay(endDate, newDateRange.endDate)
      );
    });

    if (matchingRange) {
      setActiveTab(matchingRange.label);
    } else {
      setActiveTab(null);
    }

    setFilters((prev) => ({
      ...prev,
      dateRange: newDateRange,
    }));
  };

  const clearFilters = () => {
    setFilters(getDefaultFilters());
    setActiveTab('Today');
  };

  useEffect(() => {
    const { dateRange } = filters;
    updateDateRangeAndTab(dateRange);
  }, [filters.dateRange]); 

  return (
    <FilterContext.Provider
      value={{ filters, setFilters, activeTab, setActiveTab, clearFilters, updateDateRangeAndTab }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFiltersContext must be used within a FilterProvider');
  }
  return context;
};


