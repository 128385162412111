import { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { postAgentsData } from '../api/agents';
import { DataAgentsResponse } from '../types/DataAgentsResponse';

interface UseAgentProps {
  searchParams: {};
}

export const useAgents = ({ searchParams: searchParamsProp }: UseAgentProps) => {
  const [agents, setAgents] = useState<DataAgentsResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const searchParams = useMemo(() => searchParamsProp, [searchParamsProp]);

  const fetchAgentsData = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    if (!searchParams || Object.keys(searchParams).length === 0) {
      setError('Search parameters are empty. Cannot fetch agents.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await postAgentsData(userToken, searchParams);
      setAgents(response);
    } catch (err) {
      setError('Failed to fetch agent data.');
      console.error('Error fetching agent data:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, searchParams]);
  
  useEffect(() => {
    fetchAgentsData();
  }, [fetchAgentsData]);

  return { agents, loading, error };
};
