import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getKeywordsData } from '../api/keywords';
import { DataKeywordResponse } from '../types/DataKeywordResponse';

export const useKeywordsData = () => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();

  const fetchKeywords = async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataKeywordResponse = await getKeywordsData(userToken);
      setKeywords(response.keyword);
    } catch (err) {
      console.log('Error fetching cities:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchKeywords();
    }
  }, []);
  return { keywords, loading };
};