import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { CustomerItem } from '../../components/CustomerItem';
import { CustomerCallDetail } from '../../components/CustomerCallDetail';
import FloatingChat from '../../components/FloatingChat/FloatingChat';
import { useCallLogData } from '../../hooks/useCallLogData';
import { useTextMessages } from '../../hooks/useTextMessages';
import { Loader } from '../../components/Loader';
import { useFiltersContext } from '../../contexts/FilterContext';

const CustomerDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { setSelectedCustomer, selectedCustomer } = useCustomerContext();
  const { filters } = useFiltersContext();
  const { callLog, loading: callLogLoading, error: callLogError } = useCallLogData(id);
  const { textMessages, loading: textMessagesLoading, error: textMessagesError } = useTextMessages(id);

  useEffect(() => {
    if (callLog && callLog.customer_entry) {
      setSelectedCustomer(callLog.customer_entry);
    }
  }, [callLog, setSelectedCustomer]);

  if (callLogLoading || textMessagesLoading) {
    return <div className="container"><Loader /></div>;
  }

  if (callLogError || textMessagesError) {
    return <div className="container">Error: {callLogError || textMessagesError}</div>;
  }

  if (!selectedCustomer) {
    return <div className="container">No customer selected or customer data not available.</div>;
  }

  return (
    <div className="container">
      <section className="customer-summary">
        <CustomerItem customer={selectedCustomer} />
        {callLog && <CustomerCallDetail callLog={callLog} textMessages={textMessages} />}
      </section>
      <FloatingChat customerUuid={selectedCustomer.uuid} />
    </div>
  );
};

export default CustomerDetailPage;
