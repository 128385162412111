import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { Agent } from '../types/Agent';
import { useAgents } from '../hooks/useAgents';
import { useFiltersContext } from './FilterContext';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';

interface AgentContextType {
  agents: Agent[];
  selectedAgent: Agent | null;
  setSelectedAgent: (agent: Agent | null) => void;
  loading: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  selectedSearchParam: string;
  setSelectedSearchParam: (param: string) => void;
  searchAgents: (params: { [key: string]: any }) => void;
  handleApplyFilters: () => void;
  nextPage: () => void;
  prevPage: () => void;
  pagination: {
    currentPage: number;
    totalPages: number;
    total: number;
    nextPageUrl: string | null;
    prevPageUrl: string | null;
  };
  setPagination: (pagination: {
    currentPage: number;
    totalPages: number;
    total: number;
    nextPageUrl: string | null;
    prevPageUrl: string | null;
  }) => void;
  itemsPerPage: number;
  setItemsPerPage: (items: number) => void;
  updateAgent: (updatedAgent: Agent) => void;
}

interface AgentProviderProps {
  children: ReactNode;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

const normalizePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '');
};


export const AgentProvider: React.FC<AgentProviderProps> = ({ children }) => {
  const { filters } = useFiltersContext();
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedSearchParam, setSelectedSearchParam] = useState('phoneNumber');
  const [searchParams, setSearchParams] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 1,
    nextPageUrl: null as string | null,
    prevPageUrl: null as string | null,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  const defaultParams = {
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
    page: pagination.currentPage,
    per_page: itemsPerPage,
    content: 'short',
  };

  const { agents: fetchedAgents, loading } = useAgents({ searchParams });

  const searchAgents = useCallback((params: { [key: string]: any }) => {
    setSearchParams(params);
  }, []);

  const handleApplyFilters = useCallback(() => {
    const params: { [key: string]: any } = {
      cities: filters.cities,
      states: filters.states,
      postal_codes: filters.postalCodes,
      campaign: filters.campaign,
      source: filters.source,
      keyword: filters.keyword,
      from_datetime: formatDate(filters.dateRange.startDate),
      to_datetime: formatDate(filters.dateRange.endDate),
      page: pagination.currentPage,
      per_page: itemsPerPage,
      content: 'short',
    };

    if (searchText.trim()) {
      const normalizedSearchText = normalizePhoneNumber(searchText);
  
      if (selectedSearchParam === 'phoneNumber') {
        params.customer_number = normalizedSearchText;
      } else if (selectedSearchParam === 'name') {
        params.agent_name = searchText;
      } else if (selectedSearchParam === 'callId') {
        params.call_id = searchText;
      }
    }
  
    searchAgents(params);
  }, [filters, searchText, pagination.currentPage, itemsPerPage, selectedSearchParam, searchAgents]);

  useEffect(() => {
    const params: { [key: string]: any } = {
      per_page: itemsPerPage,
      from_datetime: formatDate(filters.dateRange.startDate),
      to_datetime: formatDate(filters.dateRange.endDate),
    };
  
    if (searchText.trim()) {
      const normalizedSearchText = normalizePhoneNumber(searchText);
  
      if (selectedSearchParam === 'phoneNumber') {
        params.customer_number = normalizedSearchText;
      } else if (selectedSearchParam === 'name') {
        params.agent_name = searchText;
      } else if (selectedSearchParam === 'callId') {
        params.call_id = searchText;
      }
    }
  
    searchAgents(params);
  }, [searchText, searchAgents, itemsPerPage, selectedSearchParam]); 

  useEffect(() => {
    searchAgents(defaultParams);
  }, []);

  useEffect(() => {
    if (fetchedAgents) {
      setPagination({
        currentPage: fetchedAgents.page,
        totalPages: fetchedAgents.total_pages,
        total: fetchedAgents.total,
        nextPageUrl: fetchedAgents.next_page_url,
        prevPageUrl: fetchedAgents.prev_page_url,
      });
      setAgents(fetchedAgents.agents);
    }
  }, [fetchedAgents]);

  useEffect(() => {
    handleApplyFilters();
  }, [filters, pagination.currentPage, itemsPerPage, handleApplyFilters]);

  const nextPage = () => {
    if (pagination.nextPageUrl) {
      setSearchParams(prevParams => ({ ...prevParams, page: pagination.currentPage + 1 }));
    }
  };

  const prevPage = () => {
    if (pagination.prevPageUrl) {
      setSearchParams(prevParams => ({ ...prevParams, page: pagination.currentPage - 1 }));
    }
  };

  const updateAgent = useCallback(
    (updatedAgent: Agent) => {
      setAgents((prevAgents) =>
        prevAgents.map((agent) =>
          agent.agent_id === updatedAgent.agent_id ? updatedAgent : agent
        )
      );
    },
    [setAgents]
  );

  return (
    <AgentContext.Provider value={{
      agents,
      selectedAgent,
      setSelectedAgent,
      loading,
      searchText,
      setSearchText,
      selectedSearchParam,
      setSelectedSearchParam,
      searchAgents,
      handleApplyFilters,
      nextPage,
      prevPage,
      pagination,
      setPagination,
      itemsPerPage,
      setItemsPerPage,
      updateAgent
    }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error('useAgentContext must be used within an AgentProvider');
  }
  return context;
};