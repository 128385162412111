import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleLoginButton } from '../../components/GoogleLoginButton';
import { FeedbackForm } from '../../components/FeedbackForm';
import feedbackIcon from '../../assets/images/svg/feedback.svg';

export const LoginPage: React.FC = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      setErrorMessage(error);
    }
  }, [location]);

  return (
    <div className="container-center container-login">
      <div className="google-login-container">
        <h2>Welcome to Hot Analytics 🔥</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <GoogleLoginButton />
      </div>
      <FeedbackForm showScreenshotButton={false} className="login-feedback-icon" imageUrl={feedbackIcon} />
    </div>
  );
};
