import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import arrowRight from '../../assets/images/svg/arrow-next-wt.svg';
import arrowLeft from '../../assets/images/svg/arrow-prev-wt.svg';
import './DashboardSidebar.css';

interface MenuItemType {
  label: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

interface DashboardSidebarProps {
  menuItems: MenuItemType[];
  onMenuItemClick: (content: React.ReactNode, index: number) => void; // Update to accept both content and index
  activeIndex: number;
}

export const DashboardSidebar: React.FC<DashboardSidebarProps> = ({ menuItems, onMenuItemClick, activeIndex }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sidebar
      collapsed={collapsed}
      rootStyles={{
        ['.ps-sidebar-container']: {
          backgroundColor: 'var(--sidebar-color)',
        },
      }}
    >
      <button className="sidebar-collapser" onClick={handleToggle}>
        <img src={collapsed ? arrowRight : arrowLeft} alt="Toggle" />
      </button>
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? 'var(--sidebar-color)' : 'transparent',
            color: active ? 'var(--menu-text-color)' : 'var(--menu-text-color)',
          }),
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            data-menu-item={item.label}
            key={index}
            icon={item.icon}
            onClick={() => onMenuItemClick(item.content, index)} // Pass both content and index
            active={index === activeIndex}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Sidebar>
  );
};

