import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getSourceData } from '../api/source';
import { DataSourceResponse } from '../types/DataSourceResponse';

export const useSourceData = () => {
  const [source, setSource] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();

  const fetchSource = async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataSourceResponse = await getSourceData(userToken);
      setSource(response.source);
    } catch (err) {
      console.log('Error fetching cities:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchSource();
    }
  }, []);
  return { source, loading };
};