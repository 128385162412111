import React from 'react';
import './Pagination.css';

interface PaginationProps {
  pagination: {
    currentPage: number;
    totalPages: number;
    total: number;
    nextPageUrl: string | null;
    prevPageUrl: string | null;
  };
  nextPage: () => void;
  prevPage: () => void;
  itemsPerPage: number;
  setItemsPerPage: (items: number) => void;
  searchItems: (params: { [key: string]: any }) => void;
  itemsCount: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  nextPage,
  prevPage,
  itemsPerPage,
  setItemsPerPage,
  searchItems,
  itemsCount,
}) => {
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    searchItems({ per_page: newItemsPerPage, page: 1 });
  };

  const getCurrentRange = () => {
    const start = (pagination.currentPage - 1) * itemsPerPage + 1;
    const end = start + itemsCount - 1;
    return `${start}-${end}`;
  };

  return (
    <div className="pagination">
      <span className="pagination-info">
        Showing {getCurrentRange()} of  {pagination.total}  records
      </span>
      <button className="cta cta-secondary cta--pagination" onClick={prevPage} disabled={!pagination.prevPageUrl}>
        ❮
      </button>
      <span>
        Page {pagination.currentPage} of {pagination.totalPages}
      </span>
      <button className="cta cta-secondary cta--pagination" onClick={nextPage} disabled={!pagination.nextPageUrl}>
        ❯
      </button>
      <div className="custom-select-wrapper">
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="items-per-page-select cta cta-secondary cta--pagination"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
    </div>
  );
};