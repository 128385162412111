import React, { useState, useEffect } from 'react';
import { AdminTable } from '../AdminTable';
import { AdminRoleFeature } from '../../types/AdminRoleFeature';
import { useAdminRoleFeatures } from '../../hooks/useAdminRoleFeatures';
import { useAdminRoles } from '../../hooks/useAdminRoles';
import { useAdminFeatures } from '../../hooks/useAdminFeatures';
import { ToggleSwitch } from '../ToggleSwitch';
import { Loader } from '../Loader';
import { AdminRole } from '../../types/AdminRole';
import { AddRoleFeaturePopup } from '../AddRoleFeaturePopup/AddRoleFeaturePopup';

export const RoleFeaturesTable: React.FC = () => {
  const { roleFeatures, addOrUpdateRoleFeature, loading, error } = useAdminRoleFeatures();
  const { roles, loading: rolesLoading, error: rolesError } = useAdminRoles();
  const { features, loading: featuresLoading, error: featuresError } = useAdminFeatures();

  const [roleFeatureList, setRoleFeatureList] = useState<AdminRoleFeature[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isAddRoleFeaturePopupOpen, setIsAddRoleFeaturePopupOpen] = useState(false);

  useEffect(() => {
    if (roleFeatures) {
      setRoleFeatureList(roleFeatures);
    }
  }, [roleFeatures]);

  const processedRoleFeatureList = roleFeatureList.map((rf) => {
    const roleName = roles?.find((role: AdminRole) => role.id === rf.role_id)?.role_name || 'Unknown';
    const featureName = features?.find((feature) => feature.id === rf.feature_id)?.feature_name || 'Unknown';

    return {
      ...rf,
      role_name: roleName,
      feature_name: featureName,
    };
  });

  const filteredRoleFeatureList = processedRoleFeatureList.filter((rf) =>
    rf.role_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    rf.feature_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleFeatureAccess = async (roleId: number, featureId: number, canAccess: boolean) => {
    await addOrUpdateRoleFeature(roleId, featureId, !canAccess);

    setRoleFeatureList((prevRoleFeatures) =>
      prevRoleFeatures.map((rf) =>
        rf.role_id === roleId && rf.feature_id === featureId
          ? { ...rf, can_access: !canAccess }
          : rf
      )
    );
  };

  const handleAddRoleFeature = async (roleId: number, featureId: number) => {
    await addOrUpdateRoleFeature(roleId, featureId, true);

    setRoleFeatureList((prevRoleFeatures) => [
      ...prevRoleFeatures,
      { role_id: roleId, feature_id: featureId, can_access: true, id: Date.now(), created_at: new Date().toISOString(), updated_at: new Date().toISOString() }, // Simulate new role feature
    ]);
  };

  const columns = ['Role Name', 'Feature', 'Can Access', 'Created At', 'Updated At', 'Actions'];

  if (loading || rolesLoading || featuresLoading) return <Loader />;
  if (error || rolesError || featuresError) return <div>{error || rolesError || featuresError}</div>;

  return (
    <>
      <AdminTable<AdminRoleFeature>
        columns={columns}
        data={filteredRoleFeatureList}
        renderRow={(rf) => (
          <>
            <td>{rf.role_name}</td>
            <td>{rf.feature_name}</td>
            <td>{rf.can_access ? <span className="active">Yes</span> : <span className="inactive">No</span>}</td>
            <td>{new Date(rf.created_at).toLocaleString()}</td>
            <td>{new Date(rf.updated_at).toLocaleString()}</td>
            <td>
              <div className="admin-table-icons">
                <ToggleSwitch
                  isChecked={rf.can_access}
                  onToggle={() => handleToggleFeatureAccess(rf.role_id, rf.feature_id, rf.can_access)}
                />
              </div>
            </td>
          </>
        )}
        entityName="Role Features"
        onAdd={() => setIsAddRoleFeaturePopupOpen(true)}
        showSearchBar={true}
        onSearch={setSearchTerm}
      />

      {isAddRoleFeaturePopupOpen && (
        <AddRoleFeaturePopup
          roles={roles || []}
          features={features || []}
          onClose={() => setIsAddRoleFeaturePopupOpen(false)}
          onSave={handleAddRoleFeature}
        />
      )}
    </>
  );
};

export default RoleFeaturesTable;
