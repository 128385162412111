import React, { useState } from 'react';
import { SearchInput } from '../../components/SearchInput';
import { FilterPanel } from '../../components/FilterPanel';
import { FilterSidebar } from '../../components/FilterSidebar';
import { AgentList } from '../../components/AgentList';
import { useAgentContext } from '../../contexts/AgentContext';
import { DateRangeSelector } from '../../components/DateRangeSelector';
import { useFiltersContext } from '../../contexts/FilterContext';
import { DateRangeTabs } from '../../components/DateRangeTabs';
import { AgentStatsList } from '../../components/AgentStatsList';
import { DateTimeRangeSelector } from '../../components/DateTimeRangeSelector/DateTimeRangeSelector';

export const AgentsSearchPage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  const { searchText, setSearchText, handleApplyFilters, selectedSearchParam, setSelectedSearchParam } = useAgentContext();

  return (
    <div className="container">
      <section className="section-search-filter">
        <div className="search-bar agent-search-bar">
          <SearchInput 
            toggleFilter={toggleFilter} 
            searchText={searchText} 
            setSearchText={setSearchText}
            selectedSearchParam={selectedSearchParam}
            setSelectedSearchParam={setSelectedSearchParam}
          />
        </div>
        <div className="overall-stats-filters">
          <div className="overall-filters">  
            <div className="overall-filters__range">
              <label>Dates</label>
              <DateTimeRangeSelector />
            </div>
            <DateRangeTabs />
          </div>
          <AgentStatsList />
        </div>
        <AgentList />
      </section>

      <FilterSidebar isOpen={isFilterOpen} onClose={toggleFilter}>
        <FilterPanel toggleFilter={toggleFilter} applyFilters={handleApplyFilters} />
      </FilterSidebar>
    </div>
  );
};

