import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAgentPhoneNumbers } from '../api/agentMessages';

export const useAgentPhoneNumbers = (agentId: string | undefined) => {
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchPhoneNumbers = useCallback(async () => {
    if (!userToken || !agentId) {
      setError('Token or agentId is missing.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const phoneNumbersResponse = await getAgentPhoneNumbers(userToken, agentId);
      setPhoneNumbers(phoneNumbersResponse);
      if (phoneNumbersResponse.length > 0) {
        setSelectedPhoneNumber(phoneNumbersResponse[0]); // Select first phone number by default
      }
    } catch (err) {
      setError('Failed to fetch phone numbers.');
      console.error('Error fetching phone numbers:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, agentId]);

  useEffect(() => {
    fetchPhoneNumbers();
  }, [fetchPhoneNumbers]);

  return { phoneNumbers, selectedPhoneNumber, setSelectedPhoneNumber, loading, error };
};
