import React, { useState } from 'react';
import './MessageChatBoard.css';
import { SingleSelectDropdown } from '../SingleSelectDropdown/SingleSelectDropdown';
import agentIcon from '../../assets/images/svg/chat-agent.svg';
import customerIcon from '../../assets/images/svg/chat-customer.svg';
import { TextMessagesResponse } from '../../types/TextMessagesResponse';
import { TextMessage } from '../../types/TextMessage';

interface MessageChatBoardProps {
  textMessages: TextMessagesResponse | undefined;
}

const MessageChatBoard: React.FC<MessageChatBoardProps> = ({ textMessages }) => {
  const phoneNumbers = textMessages ? Object.keys(textMessages) : [];
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>(phoneNumbers[0] || '');

  const handlePhoneNumberChange = (selectedValue: string) => {
    setSelectedPhoneNumber(selectedValue);
  };

  const renderMessages = (messages: TextMessage[] | undefined) => {
    if (!messages) {
      return null;
    }

    let previousDirection: string | null = null;
    let previousDate: string | null = null;

    return messages.map((message, index) => {
      const messageDate = new Date(message.called_at).toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const messageTime = new Date(message.called_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      const showDate = messageDate !== previousDate;
      
      const showIcon = message.direction !== previousDirection || showDate;

      previousDirection = message.direction;
      previousDate = messageDate;

      return (
        <div key={index}>
          {showDate && <div className="message-date">{messageDate}</div>}
          <div className={`message-container ${message.direction}`}>
            {showIcon && (
              <div
                className={`chat-message__icon ${message.direction === 'msg_inbound' ? 'inbound-icon' : 'outbound-icon'}`}
                data-agent-name={message.agent_name}
              >
                <img src={message.direction === 'msg_inbound' ? customerIcon : agentIcon} alt="icon" className="chat-icon" />
              </div>
            )}
            <div className={`chat-message ${message.direction === 'msg_inbound' ? 'inbound' : 'outbound'}`}>
              <p>{message.message_body}</p>
              <p className="chat-message__featured-text"><span>{`#${message.call_id}`}</span><span>{messageTime}</span></p>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="message-chat-board">
      <div className="messages-dropdown">
        <SingleSelectDropdown
          values={phoneNumbers}
          selectedValue={selectedPhoneNumber}
          onChange={handlePhoneNumberChange}
          name="Phone Number"
        />
      </div>
      <div className="card-wrapper card-wrapper--sm">
        <div className="messages-container">
          {textMessages && renderMessages(textMessages[selectedPhoneNumber])}
        </div>
      </div>
    </div>
  );
};

export default MessageChatBoard;