import React, { useState } from 'react';
import { SearchInput } from '../../components/SearchInput';
import { CustomerList } from '../../components/CustomerList';
import { FilterPanel } from '../../components/FilterPanel';
import { FilterSidebar } from '../../components/FilterSidebar';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { DateTimeRangeSelector } from '../../components/DateTimeRangeSelector';
import { DateRangeTabs } from '../../components/DateRangeTabs';

const CustomerSearchPage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  const { searchText, setSearchText, handleApplyFilters, selectedSearchParam, setSelectedSearchParam } = useCustomerContext(); 

  return (
    <div className="container">
      <section className="section-search-filter">
          <div className="search-bar">
            <SearchInput
              toggleFilter={toggleFilter}
              searchText={searchText} 
              setSearchText={setSearchText}
              selectedSearchParam={selectedSearchParam}
              setSelectedSearchParam={setSelectedSearchParam} />
          </div>
          <div className="overall-stats-filters">
          <div className="overall-filters">  
            <div className="overall-filters__range">
              <label>Dates</label>
              <DateTimeRangeSelector />
            </div>
            <DateRangeTabs />
          </div>
        </div>
            <CustomerList />
      </section>
      <FilterSidebar isOpen={isFilterOpen} onClose={toggleFilter}>
        <FilterPanel toggleFilter={toggleFilter} applyFilters={handleApplyFilters}/>
      </FilterSidebar>
    </div>
  );
};

export default CustomerSearchPage;
