import React, { useState, useEffect } from 'react';
import { ActionPopup } from '../ActionPopup/ActionPopup';
import { AdminUser } from '../../types/AdminUser';
import { SingleSelectDropdown } from '../SingleSelectDropdown/SingleSelectDropdown';
import { AdminRole } from '../../types/AdminRole';
import { useAuth } from '../../contexts/AuthContext';

interface EditUserPopupProps {
  adminUser: AdminUser;
  roles: AdminRole[];
  onClose: () => void;
  onSave: (updatedUser: { name: string; role_id: number; updated_by: string }) => void;
}

export const EditUserPopup: React.FC<EditUserPopupProps> = ({ adminUser, roles, onClose, onSave }) => {
  const [name, setName] = useState(adminUser.name);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const userRole = roles.find(r => r.id === adminUser.role_id);
    setRoleId(userRole ? userRole.id : null);
  }, [adminUser, roles]);

  // Handle Save function with validation for the name field.
  const handleSave = async () => {
    setError(null); // Clear previous error

    // Ensure name is not empty and has at least 3 characters
    if (name.trim().length < 3) {
      setError('Name must be at least 3 characters long.');
      return;
    }

    if (roleId === null) {
      setError('Role must be selected.');
      return;
    }

    setIsLoading(true);
    try {
      const updatedUser = {
        name,
        role_id: roleId,
        updated_by: user?.email || '',
      };
      await onSave(updatedUser);
      onClose();
    } catch (err) {
      setError('Failed to save user.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionPopup
      title="Edit User"
      actionType="edit"
      onClose={onClose}
      onConfirm={handleSave}
      isLoading={isLoading}
      error={error}
    >
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter at least 3 characters"
        />
      </div>
      <div className="form-group">
        <label>Role:</label>
        <SingleSelectDropdown
          values={roles.map(role => role.role_name)}
          selectedValue={roles.find(role => role.id === roleId)?.role_name || ''}
          onChange={(selectedRoleName) => {
            const selectedRole = roles.find(role => role.role_name === selectedRoleName);
            if (selectedRole) {
              setRoleId(selectedRole.id);
            }
          }}
          name="Role"
        />
      </div>
    </ActionPopup>
  );
};
