import React from 'react';
import { getCustomStaticRanges } from '../../utils/dateRanges';
import { useFiltersContext } from '../../contexts/FilterContext';
import './DateRangeTabs.css';

const customStaticRanges = getCustomStaticRanges();

export const DateRangeTabs: React.FC = () => {
  const { activeTab, setActiveTab, updateDateRangeAndTab } = useFiltersContext();

  const handleTabClick = (label: string) => {
    const selectedRange = customStaticRanges.find(range => range.label === label)?.range();

    if (selectedRange) {
      updateDateRangeAndTab({
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
        key: 'selection',
      });
      setActiveTab(label);
    }
  };

  return (
    <div className="overall-filters__def-range">
      {customStaticRanges.map(({ label }) => (
        <p
          key={label}
          className={label === activeTab ? 'active-tab' : ''}
          onClick={() => handleTabClick(label)}
        >
          {label}
        </p>
      ))}
    </div>
  );
};
