import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getCitiesData } from '../api/cities';
import { DataCitiesResponse } from '../types/DataCitiesResponse';

export const useCitiesData = () => {
  const [cities, setCities] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();

  const fetchCities = async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataCitiesResponse = await getCitiesData(userToken);
      setCities(response.cities);
    } catch (err) {
      console.log('Error fetching cities:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchCities();
    }
  }, []);
  return { cities, loading };
};