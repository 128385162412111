import React from 'react';
import { format } from 'date-fns';
import { useCustomerContext } from '../../contexts/CustomerContext';

import './LastCallInfo.css';
import { DataCallLogResponse } from '../../types/DataCallLogResponse';

interface LastCallInfoProps {
  lastCall: DataCallLogResponse;
}

export const LastCallInfo: React.FC<LastCallInfoProps> = ({ lastCall }) => {
  const { selectedCustomer } = useCustomerContext();
  const dateFormatted = format(lastCall.last_called_at, "EEE do MMM, yyyy");
  return (
    <div className="last-call-info card-wrapper">
      <h3>Last Call made was on <span className="featured-text">{dateFormatted}</span> By {selectedCustomer?.customer_name} to <span className="featured-text">{lastCall.last_agent_name}</span></h3>
      <p><span className="featured-text">Last Call Note: </span>
      {lastCall.last_call_note ? lastCall.last_call_note : 'Last call note not available for the person'}
      </p>
    </div>
  );
};
