import React, { useState } from 'react';
import { Call } from '../../types/Call';
import { CallCard } from '../CallCard';

import './CallList.css'

interface CallListProps {
  calls: Call[];
}

export const CallList: React.FC<CallListProps> = ({ calls }) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

  const handlePlay = (audio: HTMLAudioElement) => {
    if (currentAudio && currentAudio !== audio) {
      currentAudio.pause();
    }
    setCurrentAudio(audio);
  };

  return (
    <>
      {calls.length === 0 ? (
        <p>No results found</p>
      ) : (
        <ul className="call-cards">
          {calls.map((call, index) => (
            <li key={index}>
              <CallCard call={call} onPlay={handlePlay} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
