import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAdminUsersData, updateUserActiveStatus, updateAdminUserData, addAdminUserData } from '../api/users'; // Import the API calls
import { AdminUser } from '../types/AdminUser';

export const useAdminUsers = () => {
  const [users, setUsers] = useState<AdminUser[] | null>(null); // Null if no data
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth(); // Get the token from AuthContext

  const fetchUsers = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getAdminUsersData(userToken);
      setUsers(response ?? []); // Fallback to empty array if undefined
    } catch (err) {
      setError('Failed to fetch users.');
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  const toggleUserActiveState = async (userId: number, isActive: boolean) => {
    if (!userToken) return;

    try {
      const updatedUser = await updateUserActiveStatus(userToken, userId, isActive);
      setUsers((prevUsers) => 
        prevUsers?.map(user => user.id === userId ? updatedUser : user) ?? []); // Handle undefined
    } catch (err) {
      console.error('Error updating user active status:', err);
    }
  };

  const updateUser = async (userId: number, updatedUserData: { name: string; role_id: number; updated_by: string }) => {
    if (!userToken) return;

    try {
      const updatedUser = await updateAdminUserData(userId, updatedUserData, userToken);
      setUsers((prevUsers) =>
        prevUsers?.map(user => user.id === userId ? updatedUser : user) ?? []); // Handle undefined
    } catch (err) {
      console.error('Error updating user:', err);
    }
  };

  const addUser = async (newUserData: { email: string; name: string; role_id: number; created_by: string }) => {
    if (!userToken) return;

    try {
      const newUser = await addAdminUserData(newUserData, userToken);
      setUsers((prevUsers) => [...(prevUsers ?? []), newUser]); // Add new user to the list
    } catch (err) {
      console.error('Error adding new user:', err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { users, loading, error, toggleUserActiveState, updateUser, addUser }; // Return addUser
};
