import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getPostalCodesData } from '../api/postal_codes';
import { DataPostalCodesResponse } from '../types/DataPostalCodesResponse';

export const usePostalCodesData = () => {
  const [postalCodes, setPostalCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userToken, isAuthenticated } = useAuth();
  const fetchPostalCodes = useCallback(async () => {
    if (!userToken) {
      console.log('User token is null, skipping fetch.');
      return;
    }
    setLoading(true);
    try {
      const response: DataPostalCodesResponse = await getPostalCodesData(userToken);
      setPostalCodes(response.postal_codes);
    } catch (err) {
      console.log('Error fetching postal codes:', err);
    } finally {
      setLoading(false);
    }
  }, [userToken, setPostalCodes, setLoading]);
  
  useEffect(() => {
    if (isAuthenticated) {
      fetchPostalCodes();
    }
  }, [isAuthenticated, fetchPostalCodes]);
  return { postalCodes, loading };
};