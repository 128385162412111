import React from 'react';
import { Link } from 'react-router-dom';
import agentIcon from '../../assets/images/svg/agent.svg';
import eyeIcon from '../../assets/images/svg/eye-icon.svg';
import activeDaysIcon from '../../assets/images/svg/active-days.svg';
import avgTalkIcon from '../../assets/images/svg/avg-talk.svg';
import callCountIcon from '../../assets/images/svg/call-linear.svg';
import emailIcon from '../../assets/images/svg/email.svg';
import waitTimeIcon from '../../assets/images/svg/wait-time.svg';
import holdTimeIcon from '../../assets/images/svg/hold-time.svg';
import talkTimeIcon from '../../assets/images/svg/talk-time.svg';
import './AgentItem.css';
import { Agent } from '../../types/Agent';

interface AgentProps {
  agent: Agent | null;
}

export const AgentItem: React.FC<AgentProps> = ({ agent }) => {
  if (!agent) {
    return <div>No agent data available.</div>;
  }
  return (
    <div className="agent-card card-wrapper">
      <Link to={`/agents/${agent.agent_id}`} rel="noopener noreferrer" className="agent-card__content-link">
        <div className="agent-card__content">
          <div className="agent-card__name">
            <h2>{agent.agent_name}</h2>
            <img src={eyeIcon} alt="eye icon" />
          </div>

          <div className="agent-card__content-info">
            <div className="agent-card__agent-status">
              <img src={agentIcon} alt="agent icon" />
              <div className="agent-card__status-list">
                <p><img src={emailIcon} alt="email icon" /><span>Email: </span>{agent.agent_email}</p>
                <p><img src={callCountIcon} alt="call icon" /><span>Last Call: </span>{agent.last_called_at}</p>
                <p><img src={activeDaysIcon} alt="active days icon" /><span>Active Days: </span>{agent.call_timing_analysis.active_days}</p>
                <p><img src={avgTalkIcon} alt="avg icon" /><span>Average Talk Time: </span>{agent.average_talk_time}</p>
              </div>
            </div>
            <div className="agent-card__timing-analysis">
              <div className="agent-card__status-list">
                <h4>Inbound</h4>
                <p><img src={callCountIcon} alt="call icon" /><span>Inbound Calls: </span>{agent.call_timing_analysis.inbound}</p>
                <p><img src={emailIcon} alt="email icon" /><span>Messages: </span>{agent.call_timing_analysis.inbound_message}</p>
                <p><img src={talkTimeIcon} alt="talk time icon" /><span>Talk Time mins: </span>{agent.call_timing_analysis.inbound_talktime_mins}</p>
                <p><img src={holdTimeIcon} alt="hold time icon" /><span>Hold Time mins: </span>{agent.call_timing_analysis.inbound_hold_time_mins}</p>
                <p><img src={waitTimeIcon} alt="wait time icon" /><span>Wait Time mins: </span>{agent.call_timing_analysis.inbound_wait_time_mins}</p>
              </div>
              <div className="agent-card__status-list">
                <h4>Outbound</h4>
                <p><img src={callCountIcon} alt="call icon" /><span>Outbound Calls: </span>{agent.call_timing_analysis.outbound}</p>
                <p><img src={emailIcon} alt="email icon" /><span>Messages: </span>{agent.call_timing_analysis.outbound_message}</p>
                <p><img src={talkTimeIcon} alt="talk time icon" /><span>Talk Time mins: </span>{agent.call_timing_analysis.outbound_talktime_mins}</p>
                <p><img src={holdTimeIcon} alt="hold time icon" /><span>Hold Time mins: </span>{agent.call_timing_analysis.outbound_hold_time_mins}</p>
                <p><img src={waitTimeIcon} alt="wait time icon" /><span>Wait Time mins: </span>{agent.call_timing_analysis.outbound_wait_time_mins}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
