import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { CustomerEntry } from '../types/CustomerEntry';
import { useCustomers } from '../hooks/useCustomers';
import { useFiltersContext } from './FilterContext';
import { Filters } from '../types/Filters';

interface CustomerContextType {
  customers: CustomerEntry[];
  selectedCustomer: CustomerEntry | null;
  setSelectedCustomer: (customer: CustomerEntry | null) => void;
  loading: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  selectedSearchParam: string;
  setSelectedSearchParam: (param: string) => void;
  searchCustomers: (params: { [key: string]: any }) => void;
  filters: Filters;
  handleApplyFilters: () => void;
  nextPage: () => void;
  prevPage: () => void;
  pagination: {
    currentPage: number;
    totalPages: number;
    total:number;
    nextPageUrl: string | null;
    prevPageUrl: string | null;
  };
  itemsPerPage: number;
  setItemsPerPage: (items: number) => void;
  updateCustomer: (updatedCustomer: CustomerEntry) => void;
}

interface CustomerProviderProps {
  children: ReactNode;
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

const normalizePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '');
};


export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const { filters } = useFiltersContext();
  const [customers, setCustomers] = useState<CustomerEntry[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntry | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedSearchParam, setSelectedSearchParam] = useState('phoneNumber');
  const [searchParams, setSearchParams] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total:1,
    nextPageUrl: null as string | null,
    prevPageUrl: null as string | null,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const formatDate = (date: Date) => format(toDate(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  const { customers: fetchedCustomers, loading } = useCustomers({ searchParams });

  const searchCustomers = useCallback((params: { [key: string]: any }) => {
    setSearchParams(params);
  }, []);

  const handleApplyFilters = useCallback(() => {
    const params: { [key: string]: any } = {
      cities: filters.cities,
      states: filters.states,
      postal_codes: filters.postalCodes,
      campaign: filters.campaign,
      source: filters.source,
      keyword: filters.keyword,
      from_call_count: filters.callRange.minCalls,
      to_call_count: filters.callRange.maxCalls,
      from_datetime: formatDate(filters.dateRange.startDate),
      to_datetime: formatDate(filters.dateRange.endDate),
      page: pagination.currentPage,
      per_page: itemsPerPage,
    };

    if (searchText.trim()) {
      const normalizedSearchText = normalizePhoneNumber(searchText);
  
      if (selectedSearchParam === 'phoneNumber') {
        params.customer_number = normalizedSearchText;
      } else if (selectedSearchParam === 'name') {
        params.customer_name = searchText;
      } else if (selectedSearchParam === 'callId') {
        params.call_id = searchText;
      }
    }
    searchCustomers(params);
  }, [filters, searchText, pagination.currentPage, itemsPerPage, searchCustomers, selectedSearchParam]);

  useEffect(() => {
    const params: { [key: string]: any } = {
      per_page: itemsPerPage,
      from_datetime: formatDate(filters.dateRange.startDate),
      to_datetime: formatDate(filters.dateRange.endDate),
    };

    if (searchText.trim()) {
      const normalizedSearchText = normalizePhoneNumber(searchText);
  
      if (selectedSearchParam === 'phoneNumber') {
        params.customer_number = normalizedSearchText;
      } else if (selectedSearchParam === 'name') {
        params.customer_name = searchText;
      } else if (selectedSearchParam === 'callId') {
        params.call_id = searchText;
      }
    }

    searchCustomers(params);
  }, [searchText, searchCustomers, itemsPerPage, selectedSearchParam]);

  useEffect(() => {
    if (fetchedCustomers) {
      setPagination({
        currentPage: fetchedCustomers.page,
        totalPages: fetchedCustomers.total_pages,
        total: fetchedCustomers.total,
        nextPageUrl: fetchedCustomers.next_page_url,
        prevPageUrl: fetchedCustomers.prev_page_url,
      });
      setCustomers(fetchedCustomers.customer_entries);
    }
  }, [fetchedCustomers]);

  const defaultParams = {
    from_datetime: formatDate(filters.dateRange.startDate),
    to_datetime: formatDate(filters.dateRange.endDate),
    page: pagination.currentPage,
    per_page: itemsPerPage,
  };

  useEffect(() => {
    searchCustomers(defaultParams);
  }, []);

  useEffect(() => {
    handleApplyFilters();
  }, [filters, pagination.currentPage, itemsPerPage, handleApplyFilters]);

  const nextPage = () => {
    if (pagination.nextPageUrl) {
      setSearchParams(prevParams => ({ ...prevParams, page: pagination.currentPage + 1 }));
    }
  };

  const prevPage = () => {
    if (pagination.prevPageUrl) {
      setSearchParams(prevParams => ({ ...prevParams, page: pagination.currentPage - 1 }));
    }
  };

  const updateCustomer = useCallback(
    (updatedCustomer: CustomerEntry) => {
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.uuid === updatedCustomer.uuid ? updatedCustomer : customer
        )
      );
    },
    [setCustomers]
  );

  return (
    <CustomerContext.Provider value={{
      customers,
      selectedCustomer,
      setSelectedCustomer,
      loading,
      searchText,
      setSearchText,
      selectedSearchParam,
      setSelectedSearchParam,
      searchCustomers,
      handleApplyFilters,
      filters,
      nextPage,
      prevPage,
      pagination,
      itemsPerPage,
      setItemsPerPage,
      updateCustomer
    }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomerContext must be used within a CustomerProvider');
  }
  return context;
};