import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { OverallStats } from '../types/OverallStats';
import { postOverallStatsData } from '../api/overallStats';
import { useFiltersContext } from '../contexts/FilterContext';

interface UseOverallStatsProps {
  from_datetime: string;
  to_datetime: string;
}

export const useOverallStats = ({ from_datetime, to_datetime }: UseOverallStatsProps) => {
  const { filters } = useFiltersContext();
  const [overallStats, setOverallStats] = useState<OverallStats | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userToken } = useAuth();

  const fetchOverallStatsData = useCallback(async () => {
    if (!userToken) {
      setError('User token is not available.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await postOverallStatsData(userToken, { from_datetime, to_datetime });
      setOverallStats(response);
    } catch (err) {
      setError('Failed to fetch overall stats data.');
    } finally {
      setLoading(false);
    }
  }, [userToken, filters.dateRange]);

  useEffect(() => {
    fetchOverallStatsData();
  }, [fetchOverallStatsData]);
  

  return { overallStats, loading, error };
};
