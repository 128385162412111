import { format } from 'date-fns';
import { Filters } from '../types/Filters'; // Adjust the path if needed

export const formatAppliedFilters = (filters: Filters): string => {
  const { dateRange, callRange, cities, states, postalCodes, campaign, source, keyword } = filters;

  const formattedDateRange = `From ${format(dateRange.startDate, 'MM/dd/yyyy HH:mm')} to ${format(dateRange.endDate, 'MM/dd/yyyy HH:mm')}`;
  const formattedCallRange = `Calls: ${callRange.minCalls} - ${callRange.maxCalls}`;

  const formattedCities = cities.length > 0 ? `Cities: ${cities.join(', ')}` : '';
  const formattedStates = states.length > 0 ? `States: ${states.join(', ')}` : '';
  const formattedPostalCodes = postalCodes.length > 0 ? `Postal Codes: ${postalCodes.join(', ')}` : '';
  const formattedCampaign = campaign.length > 0 ? `Campaigns: ${campaign.join(', ')}` : '';
  const formattedSource = source.length > 0 ? `Source: ${source.join(', ')}` : '';
  const formattedKeyword = keyword.length > 0 ? `Keyword: ${keyword.join(', ')}` : '';

  return [
    formattedDateRange,
    formattedCallRange,
    formattedCities,
    formattedStates,
    formattedPostalCodes,
    formattedCampaign,
    formattedSource,
    formattedKeyword,
  ]
    .filter((filter) => filter !== '')
    .join(', ');
};
