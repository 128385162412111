
export const BASE_URL: string = 'https://api.hotanalytics.ai/api/v1/';

// export const BASE_URL: string = 'http://0.0.0.0:8000/api/v1/';

// export const BASE_URL: string = 'http://localhost:8000/api/v1/';

// export const BASE_URL: string = 'https://dev.api.hotanalytics.ai/api/v1/';

export const GOOGLE_CLIENT_ID = '833661262737-4m5nog8llhf52nqnac2s1k1a7khec8pa.apps.googleusercontent.com';

export const ENV='prod'; // prod

export const EMAIL_JS_CONFIG = {
  SERVICE_ID: 'service_44ldm2q',
  TEMPLATE_ID: 'template_0s9zkd5',
  USER_ID: '6_brhj07HSDWQzLyS',
};